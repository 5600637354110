import axios from "axios";

const protocol = window.location.protocol;
// const localUrl = "http://192.168.0.111:4000/api/";
const localUrl = "http://localhost:4000/api/";

const baseURL =
  protocol === "http:"
    ? localUrl
    : "https://staging-rolocheck-api.ardhika.com/api/";

const axiosInstance = axios.create({
  baseURL: baseURL,

  headers: {
    "Content-Type": "application/json",
  },
});

// handle 401 unauthorized user
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) window.location.href = "/login";
    return Promise.reject(error);
  }
);

let token = localStorage.getItem("token");
if (token)
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

export default axiosInstance;
