export const priorityList = [
  { name: "High", id: 1 },
  { name: "Medium", id: 2 },
  { name: "Low", id: 3 },
];

export const frequencyTypes = [
  {
    name: "Daily",
    key: "D",
  },
  {
    name: "Weekly",
    key: "W",
  },
  {
    name: "Monthly",
    key: "M",
  },
];
