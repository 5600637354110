import axios from "@/plugins/axios";
import { throwError } from "@/helpers/error";

const state = {
  departmentList: [],
  currentDepartment: {},
  filter: "",
};

const mutations = {
  setDepartmentList(state, list) {
    state.departmentList = list;
  },
  setDepartment(state, department) {
    state.currentDepartment = department;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  resetState: (state) => {
    state.departmentList = [];
    state.currentDepartment = {};
    state.filter = "";
  },
};

const actions = {
  async getDepartments({ commit, state }) {
    let url = "/departments";
    if (state.filter) url = `${url}?active=${state.filter}`;
    try {
      const res = await axios.get(url);
      commit("setDepartmentList", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getDepartmentById({ commit }, departmentId) {
    try {
      const res = await axios.get(`/departments/${departmentId}`);
      commit("setDepartment", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async createDepartment({ dispatch }, payload) {
    try {
      const res = await axios.post("/departments/", { department: payload });
      dispatch("getDepartments");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async updateDepartment({ dispatch }, payload) {
    try {
      const res = await axios.put(`/departments/${payload.id}`, {
        department: payload,
      });
      dispatch("getDepartments");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async deleteDepartment({ dispatch }, { active, id }) {
    const data = { active: !active };
    try {
      const res = await axios.put(`/departments/${id}`, { department: data });
      dispatch("getDepartments");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
