<template>
  <div class="ma-4 pa-4">
    <h2 class="font-weight-medium">INVENTORY ITEM</h2>
    <searchBar
      @updateSearch="(v) => (search = v)"
      @add="(dialog = true), (editMode = false)"
      @filterChanged="filterChanged"
    />
    <v-row>
      <v-col cols="12">
        <v-sheet outlined>
          <v-data-table
            :headers="inventoryItemsHeader"
            :items="inventoryItems"
            :search="search"
            fixed-header
          >
            <template #[`item.action`]="{ item }">
              <v-icon small class="mr-2" color="accent" @click="edit(item)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-btn
                x-small
                depressed
                color="accent2"
                @click="(deleteDialog = true), (currentItem = item)"
              >
                <v-icon left dark :color="item.active ? 'success' : 'error'"
                  >mdi-circle-medium</v-icon
                >{{ item.active ? "active" : "inactive" }}
              </v-btn>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500" persistent>
      <inventoryItemDialog
        v-if="dialog"
        @closeDialog="dialog = false"
        :inventoryItem="inventoryItem"
        :edit="editMode"
        @save="save"
      />
    </v-dialog>
    <deleteDialogBox
      v-if="deleteDialog"
      :show="deleteDialog"
      @delete="deleteItem"
      @close="deleteDialog = false"
      title="inventory item"
      :active="currentItem.active"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import searchBar from "@/components/searchBar.vue";
import { inventoryItemsHeader } from "@/helpers/tableHeaders.js";
import inventoryItemDialog from "@/components/inventoryItem/form.vue";
import deleteDialogBox from "@/components/deleteDialog.vue";

export default {
  components: {
    searchBar,
    inventoryItemDialog,
    deleteDialogBox,
  },
  data() {
    return {
      search: null,
      dialog: false,
      editMode: false,
      inventoryItemsHeader,
      deleteDialog: false,
      currentItem: {},
      inventoryItem: {},
    };
  },
  computed: {
    ...mapState({
      inventoryItems: (state) => state.inventoryItem.inventoryItems,
    }),
  },
  created() {
    this.setFilter("");
    this.init();
  },
  methods: {
    ...mapMutations("inventoryItem", ["setFilter"]),
    ...mapMutations("snack", ["showSnackbar"]),
    ...mapActions("inventoryItem", [
      "getInventoryItems",
      "createInventoryItem",
      "updateInventoryItem",
      "deleteInventoryItem",
    ]),
    init() {
      this.getInventoryItems();
    },
    async save(data) {
      try {
        const cb = !this.editMode
          ? this.createInventoryItem
          : this.updateInventoryItem;
        const message = !this.editMode ? "Created" : "Updated";
        await cb(data);
        this.showSnackbar({
          message: `${message} successfully`,
        });
        this.resetValues();
      } catch (error) {
        this.showSnackbar({
          message: error,
          color: "error",
        });
      }
    },
    edit(item) {
      this.inventoryItem = { ...item };
      this.editMode = true;
      this.dialog = true;
    },
    async deleteItem() {
      try {
        await this.deleteInventoryItem(this.currentItem);
        this.showSnackbar({
          message: `The inventory item has been marked as ${
            this.currentItem.active ? "inactive" : "active"
          } successfully`,
        });
        this.resetValues();
      } catch (error) {
        this.showSnackbar({
          message: error,
          color: "error",
        });
      }
    },
    resetValues() {
      this.editMode = false;
      this.dialog = false;
      this.deleteDialog = false;
      this.inventoryItem = {};
    },
    filterChanged(v) {
      this.setFilter(v);
      this.init();
    },
  },
};
</script>

<style></style>
