<template>
  <v-card class="secondary">
    <v-card-title class="font-weight-medium secondary--text primary">
      {{ edit ? `Edit ${site.name}` : "Add Store" }}
      <v-spacer></v-spacer>
      <v-btn
        class="d-flex justify-end"
        icon
        plain
        @click="$emit('closeDialog')"
      >
        <v-icon color="secondary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-sheet>
        <v-tabs v-model="currentTab" background-color="secondary">
          <v-tabs-slider color="accent"></v-tabs-slider>
          <template v-for="tab in tabItems">
            <v-tab :key="tab" class="primary--text">
              {{ tab }}
            </v-tab>
          </template>
        </v-tabs>
        <v-tabs-items v-model="currentTab" class="secondary">
          <v-tab-item>
            <DetailForm
              ref="detail"
              :data="form"
              :timeZones="timeZones"
              :edit="edit"
              :supervisorList="supervisorList"
              :auditorList="auditorList"
              :departmentList="departmentList"
              @valueChanged="(v) => (form = v)"
            />
          </v-tab-item>
          <v-tab-item>
            <HolidayForm
              :data="form.holidays"
              @valueChanged="(v) => (form.holidays = v)"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-sheet>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn rounded depressed outlined color="accent" @click="save">{{
        edit ? "Update" : "Save"
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { rules } from "@/helpers/formValidation";
import DetailForm from "./detailForm.vue";
import HolidayForm from "./holidaysForm.vue";

export default {
  props: {
    site: {
      type: Object,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    DetailForm,
    HolidayForm,
  },
  data() {
    return {
      tabItems: ["Detail", "Non-operational Days"],
      currentTab: 0,
      rules,
      form: {
        address: {},
      },
      defaultForm: "",
    };
  },
  computed: {
    ...mapState({
      auditorList: (state) => state.user.auditorList,
      supervisorList: (state) => state.user.supervisorList,
      timeZones: (state) => state.site.timeZones,
      departmentList: (state) => state.department.departmentList,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations("department", ["setFilter"]),
    ...mapMutations("snack", ["showSnackbar"]),
    ...mapActions("user", ["getAuditorUser", "getSupervisorUser"]),
    ...mapActions("site", ["getTimeZones"]),
    ...mapActions("department", ["getDepartments"]),

    init() {
      if (this.edit) {
        this.form = { ...this.site };
        this.defaultForm = JSON.stringify(this.site);
      }
      this.getAuditorUser({ active: false });
      this.getSupervisorUser({ active: false });
      this.getTimeZones();
      // this.setFilter(true);
      this.getDepartments();
    },
    save() {
      const form1 = this.$refs.detail.$refs.form.validate();

      if (!form1) return (this.currentTab = 0);

      if (this.edit) {
        delete this.form.departmentIds;
        const defaultForm = JSON.parse(this.defaultForm);
        const keys = Object.keys(defaultForm);
        const validate = [];
        for (let i = 0; i < keys.length; i++) {
          if (
            typeof defaultForm[keys[i]] != "object" &&
            this.form[keys[i]] != defaultForm[keys[i]]
          ) {
            validate.push(true);
          } else if (
            typeof defaultForm[keys[i]] == "object" &&
            JSON.stringify(this.form[keys[i]]) !=
              JSON.stringify(defaultForm[keys[i]])
          ) {
            validate.push(true);
          }
        }
        if (validate.includes(true)) {
          this.$emit("save", this.form);
        } else {
          this.showSnackbar({
            message: "There are no updates to be made.",
            color: "error",
          });
        }
      } else {
        this.$emit("save", this.form);
      }
    },
  },
};
</script>

<style scoped>
.dateList {
  overflow: auto;
}
</style>
