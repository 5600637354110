<template>
  <v-row>
    <v-col cols="3">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        hide-details
        label="Search"
        outlined
        dense
        :disabled="searchField"
        @input="$emit('updateSearch', search)"
      ></v-text-field>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="4" class="d-flex justify-end px-0">
      <v-select
        v-if="showFilter"
        v-model="filter"
        :items="types"
        :disabled="filterField"
        item-text="name"
        item-value="code"
        outlined
        dense
        hide-details
        @change="$emit('filterChanged', filter)"
      ></v-select>
      <v-btn
        class="accent white--text ml-2 mr-3"
        :disabled="addField"
        @click="$emit('add')"
      >
        Add
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
    searchField: {
      type: Boolean,
      default: false,
    },
    filterField: {
      type: Boolean,
      default: false,
    },
    addField: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      filter: "",
      types: [
        { name: "All", code: "" },
        { name: "Active", code: "true" },
        { name: "Inactive", code: "false" },
      ],
    };
  },
};
</script>
