import axios from "@/plugins/axios";
import { throwError } from "@/helpers/error";

const state = {
  stocks: [],
  stores: [],
};

const mutations = {
  setStocks(state, list) {
    state.stocks = list;
  },
  setStores(state, list) {
    state.stores = list;
  },
  resetState: (state) => {
    state.stocks = [];
    state.stores = [];
  },
};

const actions = {
  async getStocks({ commit }, store) {
    let url = `/stocks?store_id=${store}`;
    try {
      const res = await axios.get(url);
      commit("setStocks", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async addNewStock({ dispatch }, payload) {
    try {
      const res = await axios.post("/inventory_transactions", {
        inventory_transaction: payload,
      });
      dispatch("getStocks", payload.store_id);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async updateStock({ dispatch }, payload) {
    try {
      const res = await axios.put(`/inventory_transactions/${payload.id}`, {
        inventory_transaction: payload,
      });
      dispatch("getStocks", payload.store_id);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getStoreByStocks({ commit }) {
    let url = `/stores/by_stocks`;
    try {
      const res = await axios.get(url);
      commit("setStores", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
