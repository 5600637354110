import axios from "@/plugins/axios";
import { throwError } from "@/helpers/error";

const state = {
  rolesList: [],
};

const mutations = {
  setRolesList(state, list) {
    state.rolesList = list;
  },
  resetState: (state) => {
    state.rolesList = [];
  },
};

const actions = {
  async getRoles({ commit }) {
    try {
      const res = await axios.get("/roles");
      commit("setRolesList", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
