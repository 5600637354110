import axios from "@/plugins/axios";
import { throwError } from "@/helpers/error";
const state = {
  dashboard: [],
};

const mutations = {
  setDashboard(state, list) {
    state.dashboard = list;
  },
  resetState: (state) => {
    state.dashboard = [];
  },
};

const actions = {
  async getDashboardData({ commit }, payload) {
    try {
      const res = await axios.get(
        `/dashboards?from_date=${payload.from_date}&to_date=${payload.to_date}`
      );
      commit("setDashboard", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
