import axios from "@/plugins/axios";
import { throwError } from "@/helpers/error";

const state = {
  siteList: [],
  currentSite: {},
  timeZones: [],
  filter: "",
};

const mutations = {
  setSiteList(state, list) {
    state.siteList = list;
  },
  setSite(state, site) {
    state.currentSite = site;
  },
  setTimeZones(state, list) {
    state.timeZones = list;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  resetState: (state) => {
    state.siteList = [];
    state.currentSite = {};
    state.timeZones = [];
    state.filter = "";
  },
};

const actions = {
  async getSites({ commit }) {
    let url = "/stores";
    if (state.filter) url = `${url}?active=${state.filter}`;
    try {
      const res = await axios.get(url);
      commit("setSiteList", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getSiteById({ commit }, siteId) {
    try {
      const res = await axios.get(`/stores/${siteId}`);
      commit("setSite", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async createSite({ dispatch }, payload) {
    try {
      const res = await axios.post("/stores/", { store: payload });
      dispatch("getSites");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async updateSite({ dispatch }, payload) {
    try {
      const res = await axios.put(`/stores/${payload.id}`, { store: payload });
      dispatch("getSites");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async deleteSite({ dispatch }, { active, id }) {
    try {
      const data = { active: !active };
      const res = await axios.put(`/stores/${id}`, { store: data });
      dispatch("getSites");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getTimeZones({ commit }) {
    try {
      const res = await axios.get(`timezones`);
      commit("setTimeZones", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
