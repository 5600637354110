<template>
  <v-row class="fill-height">
    <v-col v-if="showChart">
      <Doughnut
        class="chart-container"
        :chartData="chartData"
        :chartOptions="chartOptions"
      />
    </v-col>
    <v-col v-else cols="12" class="d-flex align-center justify-center">
      <h3 class="body-1">No Data Available</h3>
    </v-col>
  </v-row>
</template>

<script>
import Doughnut from "@/components/dashboard/charts/doughnut";

export default {
  components: {
    Doughnut,
  },
  props: {
    chartData: { type: Object, default: () => {}, required: true },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
          },
        },
      },
      // chartData: {
      //   labels: ["Completed Reports", "Missed Reports", "Pending Reports"],
      //   datasets: [
      //     {
      //       backgroundColor: ["#6AC0E1", "#0978FA", "#72F0CF"],
      //       data: [4, 2, 6],
      //     },
      //   ],
      // },
    };
  },
  computed: {
    showChart() {
      const value = this.chartData.datasets.map((i) => i.data.some((v) => v));
      return value.includes(true);
    },
  },
  created() {},
};
</script>

<style scoped>
.chart-container {
  padding: 10px;
  max-height: 300px;
}
</style>
