import axios from "@/plugins/axios";
import { throwError } from "@/helpers/error";

const state = {
  issueCategoryList: [],
  filter: "",
};
const mutations = {
  setIssueCategoryList(state, list) {
    state.issueCategoryList = list;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  resetState: (state) => {
    state.issueCategoryList = [];
    state.filter = "";
  },
};

const actions = {
  async getIssueCategories({ commit }) {
    let url = "/issue_categories";
    if (state.filter) url = `${url}?active=${state.filter}`;
    try {
      const res = await axios.get(url);
      commit("setIssueCategoryList", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getIssueCategoryById(_, id) {
    try {
      const res = await axios.get(`/issue_categories/${id}`);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async createIssueCategory({ dispatch }, payload) {
    try {
      const res = await axios.post("/issue_categories/", {
        issue_categories: payload,
      });
      dispatch("getIssueCategories");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async updateIssueCategory({ dispatch }, payload) {
    try {
      const res = await axios.put(`/issue_categories/${payload.id}`, {
        issue_categories: payload,
      });
      dispatch("getIssueCategories");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async deleteIssueCategory({ dispatch }, { active, id }) {
    try {
      const data = { active: !active };
      const res = await axios.put(`/issue_categories/${id}`, {
        issue_categories: data,
      });
      dispatch("getIssueCategories");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
