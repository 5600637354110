<template>
  <div class="ma-4 pa-4">
    <h2 class="font-weight-medium">ISSUE LIST REPORTS</h2>
    <v-form ref="form">
      <v-row>
        <v-col cols="3">
          <v-menu
            v-model="fromDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="formattedStartDate"
                outlined
                dense
                label="From Date*"
                hide-details="auto"
                background-color="bg"
                append-icon="mdi-calendar"
                :rules="[rules.required]"
                v-bind="attrs"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.from_date"
              @input="fromDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="toDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="formattedEndDate"
                label="To Date*"
                outlined
                dense
                :placeholder="
                  !search.from_date ? 'choose a from date first' : ''
                "
                hide-details="auto"
                background-color="bg"
                append-icon="mdi-calendar"
                :disabled="!search.from_date"
                :rules="[rules.required]"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.to_date"
              :min="getEndDate()"
              @input="toDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="search.store_id"
            label="Store"
            outlined
            dense
            :items="siteList"
            item-text="name"
            item-value="id"
            background-color="bg"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="search.assigned_user_id"
            label="Assigned User"
            outlined
            dense
            :items="userList"
            item-text="name"
            item-value="id"
            background-color="bg"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="search.status"
            label="Status"
            outlined
            dense
            :items="status"
            background-color="bg"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>

        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end pt-3">
          <v-btn
            rounded
            depressed
            outlined
            color="accent"
            class="mr-5"
            :disabled="!search.from_date || !search.to_date"
            @click="onGenerateReport"
          >
            <v-icon class="mr-1">mdi-folder-text-outline</v-icon>
            Generate
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            color="accent"
            @click="onClearFilter"
          >
            <v-icon class="mr-1">mdi-close</v-icon>
            Clear
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-sheet v-if="valid" class="mt-2" outlined>
      <v-data-table :headers="headers" :items="IssueReport" fixed-header>
        <template #[`item.due_date_time`]="{ item }">
          <v-chip
            :color="
              item.completed_in_range === 'Not completed'
                ? 'red'
                : item.completed_in_range
                ? 'green'
                : 'amber'
            "
            dark
          >
            {{ formatDateTime(item.due_date_time) }}
          </v-chip>
        </template>
        <template #[`item.action`]="{ item }">
          <v-icon class="mr-2" color="accent" @click="view(item)">
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </v-sheet>
    <v-dialog v-model="dialog" fullscreen>
      <v-card>
        <v-app-bar flat dark color="primary ">
          <v-toolbar-title class="text-uppercase">{{
            issueDetails.title
          }}</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>
        <v-card-text>
          <v-card outlined class="pa-5 my-5">
            <v-sheet flat color="bg" class="pa-2">
              <v-row dense>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Store name</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        issueDetails.store && issueDetails.store.name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Assigned Department</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        issueDetails.assigned_department &&
                        issueDetails.assigned_department.name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Assigned User</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        issueDetails.assigned_user &&
                        issueDetails.assigned_user.name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Priority</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        issueDetails.priority
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Status</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        issueDetails.status
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Created Date Time</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        issueDetails.created_date_time &&
                        formatDateTime(issueDetails.created_date_time)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Due Date Time</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        issueDetails.due_date_time &&
                        formatDateTime(issueDetails.due_date_time)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Attachments</v-list-item-title
                      >
                      <v-list-item-subtitle
                        ><v-icon
                          :disabled="!issueDetails.is_attachments_available"
                          @click="getImages(issueDetails.id, 'issue')"
                          color="primary"
                          >mdi-image</v-icon
                        ></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="12">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Comment</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        issueDetails.comments
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-sheet>
          </v-card>
          <v-timeline dense>
            <v-timeline-item
              v-for="(event, ind) in issueDetails.issueEvent"
              :key="ind"
            >
              <p class="body-1">
                {{ event.created_user.name }} on
                {{ formatDateTime(event.created_date_time) }}
              </p>
              <p class="body-2">
                {{ event.value }}
                <v-icon
                  v-if="event.is_attachments_available"
                  @click="getImages(event.id, 'event')"
                  color="primary"
                  >mdi-image</v-icon
                >
              </p>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showImage"
      persistent
      scrollable
      :max-width="getDynamicWidth()"
    >
      <v-card class="secondary">
        <v-card-title class="font-weight-medium secondary--text primary">
          Attachments
          <v-spacer></v-spacer>
          <v-btn icon plain @click="(showImage = false), (images = [])">
            <v-icon color="secondary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-2" custom-scroll>
          <v-container>
            <v-row v-if="images.length > 0">
              <v-col
                v-for="image in images"
                :key="image.id"
                class="d-flex flex-column pa-2"
                cols="4"
              >
                <v-img
                  v-if="image.type === 'image'"
                  :src="`data:image/png;base64,${image.file_binary}`"
                  aspect-ratio="1"
                  @click="selectedImage = image.file_binary"
                  width="200"
                  height="200"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-btn
                  v-if="image.type === 'application'"
                  @click="downloadPdf(image)"
                  width="200"
                  height="200"
                >
                  <v-icon size="200" color="red">mdi-file-pdf-box </v-icon>
                </v-btn>
                <video
                  v-if="image.type === 'video'"
                  controls
                  width="200"
                  height="200"
                  :src="image.videoUrl"
                />
                <p class="text-truncate mb-0">
                  {{ image.name }}
                </p>
              </v-col>
            </v-row>
            <v-row v-else class="flex justify-center pa-8">
              No Attachments
            </v-row>
          </v-container>
          <v-overlay v-if="selectedImage">
            <div class="overlay_btn">
              <v-btn fab color="white" @click="selectedImage = null"
                ><v-icon class="text-h4" color="blue">mdi-close</v-icon></v-btn
              >
            </div>
            <div>
              <v-img
                :src="`data:image/png;base64,${selectedImage}`"
                contain
                @click="selectedImage = null"
                class="image_cover"
              ></v-img>
            </div>
          </v-overlay>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { issueReportHeader } from "@/helpers/tableHeaders.js";
import { rules } from "@/helpers/formValidation";
import { formatDate, formatDateTime } from "@/helpers/common";

export default {
  data() {
    return {
      search: {
        store_id: null,
        assigned_user_id: null,
        from_date: null,
        to_date: null,
      },
      fromDate: false,
      toDate: false,
      formattedStartDate: null,
      formattedEndDate: null,
      rules,
      formatDate,
      formatDateTime,
      valid: false,
      headers: issueReportHeader,
      dialog: false,
      issueDetails: {},
      showImage: false,
      images: [],
      selectedImage: null,
      status: ["All", "Open", "Reopen", "Resolved", "Completed"],
    };
  },
  watch: {
    "search.from_date"(v) {
      this.formattedStartDate = v ? this.formatDate(v) : null;
    },
    "search.to_date"(v) {
      this.formattedEndDate = v ? this.formatDate(v) : null;
    },
    search: {
      handler() {
        this.valid = false;
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState({
      userList: (state) => state.user.usersList,
      siteList: (state) => state.site.siteList,
      IssueReport: (state) => state.report.issueReports,
    }),
  },
  methods: {
    ...mapActions("user", ["getUsers"]),
    ...mapActions("site", ["getSites"]),
    ...mapActions("report", [
      "getIssueReports",
      "getIssueEventById",
      "getAttachmentsByEvent",
      "getAttachmentsByIssue",
    ]),

    init() {
      this.getUsers();
      this.getSites();
    },
    getEndDate() {
      if (this.search.to_date && this.search.from_date > this.search.to_date) {
        this.search.to_date = null;
        this.toDate = "";
        return;
      }
      return this.search.from_date;
    },
    onGenerateReport() {
      if (this.$refs.form.validate()) {
        const search = this.objectToQueryString(this.search);
        this.getIssueReports(search).then(() => {
          this.valid = true;
        });
      } else {
        this.$notifier.showMessage({
          msg: "Please correct the error(s) in the form to proceed further",
          color: "error",
        });
      }
    },
    objectToQueryString(obj) {
      const keyValuePairs = [];
      for (const key in obj) {
        if (key === "status" ? obj[key] !== "All" : obj[key] !== null)
          keyValuePairs.push(
            encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
          );
      }
      return "?" + keyValuePairs.join("&");
    },
    onClearFilter() {
      this.search = {
        store_id: null,
        user_id: null,
        from_date: null,
        to_date: null,
      };
      this.valid = false;
      this.fromDate = "";
      this.toDate = "";
      this.$refs.form.resetValidation();
    },
    view(item) {
      this.dialog = true;

      this.getIssueEventById(item.id).then((res) => {
        this.issueDetails = { ...item, issueEvent: res };
      });
    },
    async getImages(id, type) {
      this.images =
        type === "event"
          ? await this.getAttachmentsByEvent(id)
          : await this.getAttachmentsByIssue(id);
      this.images = this.images.map((i) => {
        if (i.type === "video") {
          const blob = this.base64ToBlob(i.file_binary, "video/mp4");
          const dataUrl = URL.createObjectURL(blob);
          return { ...i, videoUrl: dataUrl };
        }
        return i;
      });
      this.showImage = true;
    },
    base64ToBlob(base64String, mimeType) {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      return new Blob([byteArray], { type: mimeType });
    },
    downloadPdf(i) {
      const blob = this.base64ToBlob(i.file_binary, "application/pdf");
      const dataUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = `${i.name}.pdf`; // Set the desired filename

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the click event to start the download
      link.click();
    },
    getDynamicWidth() {
      if (this.images && this.images.length > 0) {
        return "65%";
      }
      return "500px";
    },
  },
};
</script>

<style scoped>
.overlay_btn {
  position: fixed;
  top: 10px;
  right: 30px;
}
.image_cover {
  width: 1200px;
  height: 1200px;
  object-fit: cover;
}
</style>
