<template>
  <v-main app>
    <navigationDrawer />
  </v-main>
</template>

<script>
import navigationDrawer from "@/components/navigationDrawer.vue";
export default {
  name: "App",
  components: {
    navigationDrawer,
  },

  data: () => ({}),
};
</script>
