import axios from "@/plugins/axios";
import { throwError } from "@/helpers/error";

const state = {
  usersList: [],
  auditorList: [],
  supervisorList: [],
  filter: "",
};

const mutations = {
  setUsersList(state, list) {
    state.usersList = list;
  },
  setAuditorList(state, list) {
    state.auditorList = list;
  },
  setSupervisorList(state, list) {
    state.supervisorList = list;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  resetState: (state) => {
    state.usersList = [];
    state.auditorList = [];
    state.supervisorList = [];
    state.filter = "";
  },
};

const actions = {
  async getUsers({ commit, state }) {
    let url = "/users";
    if (state.filter) url = `${url}?active=${state.filter}`;
    try {
      const res = await axios.get(url);
      commit("setUsersList", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getUserById(_, userId) {
    try {
      const res = await axios.get(`/users/${userId}`);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async createUser({ dispatch }, payload) {
    try {
      const res = await axios.post("/users/", { user: payload });
      dispatch("getUsers");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async updateUser({ dispatch }, payload) {
    try {
      const res = await axios.put(`/users/${payload.id}`, { user: payload });
      dispatch("getUsers");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async deleteUser({ dispatch }, { active, id }) {
    try {
      const data = { active: !active };
      const res = await axios.put(`/users/${id}`, { user: data });
      dispatch("getUsers");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },

  async getAuditUserByStoreAndAudit({ commit }, { audit, store }) {
    let url = `/users/${audit}/audit/${store}/store`;
    try {
      const res = await axios.get(url);
      commit("setAuditorList", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getAuditorUser({ commit }, { active = true }) {
    try {
      const res = await axios.get(
        `/users?role_id=4${active ? "&active=true" : ""}`
      );
      commit("setAuditorList", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getSupervisorUser({ commit }, { active = true }) {
    try {
      const res = await axios.get(
        `/users?role_id=3${active ? "&active=true" : ""}`
      );
      commit("setSupervisorList", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
