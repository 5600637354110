<template>
  <v-card>
    <v-form ref="form">
      <v-card-title class="font-weight-bold white--text primary">
        {{ edit ? "Edit" : "Add" }} Issue Category
        <v-spacer></v-spacer>
        <v-btn
          class="d-flex justify-end"
          icon
          plain
          @click="$emit('closeDialog')"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-text-field
          v-model.trim="form.name"
          label="Title*"
          outlined
          dense
          :rules="[rules.required]"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded depressed outlined color="accent" @click="save">{{
          edit ? "Update" : "Save"
        }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { rules } from "@/helpers/formValidation";
export default {
  props: {
    issueCategory: {
      type: Object,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      form: {},
      rules,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.edit) this.form = { ...this.issueCategory };
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.$emit("save", this.form);
    },
  },
};
</script>

<style></style>
