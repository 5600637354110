import axios from "@/plugins/axios";
import { throwError } from "@/helpers/error";

const state = {
  auditReports: [],
  issueReports: [],
  transactionReports: [],
};

const mutations = {
  auditReportList(state, list) {
    state.auditReports = list;
  },
  issueReportList(state, list) {
    state.issueReports = list;
  },
  transactionReportList(state, list) {
    state.transactionReports = list;
  },
  resetState: (state) => {
    state.auditReports = [];
    state.issueReports = [];
    state.transactionReports = [];
  },
};

const actions = {
  async getAuditReports({ commit }, query_params) {
    let url = `/audit_reports` + query_params;
    try {
      const res = await axios.get(url);
      commit("auditReportList", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getStores(_, query_params) {
    let url = "/stores" + query_params;
    try {
      const res = await axios.get(url);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getAuditsByStore(_, query_params) {
    let url = `/workorder_schedulers` + query_params;
    try {
      const res = await axios.get(url);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getAudits(_, query_params) {
    let url = `/audits` + query_params;
    try {
      const res = await axios.get(url);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getSections(_, query_params) {
    let url = `/sections` + query_params;
    try {
      const res = await axios.get(url);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getQuestions(_, query_params) {
    let url = `/questions` + query_params;
    try {
      const res = await axios.get(url);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getImagesByQuestionId(_, id) {
    let url = `/answer_attachments?answer_id=${id}`;
    try {
      const res = await axios.get(url);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getIssueReports({ commit }, query_params) {
    let url = `/issue_reports` + query_params;
    try {
      const res = await axios.get(url);
      commit("issueReportList", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getTransactionReports({ commit }, query_params) {
    let url = "/transaction_reports" + query_params;
    try {
      const res = await axios.get(url);
      commit("transactionReportList", res.data?.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getIssueEventById(_, id) {
    try {
      const res = await axios.get(`/issue_events?issue_list_id=${id}`);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getAttachmentsByEvent(_, id) {
    try {
      const res = await axios.get(`/event_attachments?event_id=${id}`);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getAttachmentsByIssue(_, id) {
    try {
      const res = await axios.get(`/issue_attachments?issue_list_id=${id}`);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
