<template>
  <div class="ma-4 pa-4">
    <h2 class="font-weight-medium">DASHBOARD</h2>
    <h2 class="body-1">Executive Summary</h2>
    <v-form ref="form">
      <v-row>
        <v-col cols="4" class="mt-3">
          <v-menu
            v-model="startDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="fromDateFormat"
                label="From Date*"
                outlined
                dense
                v-on="on"
                v-bind="attrs"
                readonly
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.from_date"
              @input="startDate = false"
              @change="form.from_date > form.to_date ? (form.to_date = '') : ''"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4" class="mt-3">
          <v-menu
            v-model="endDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="toDateFormat"
                label="To Date*"
                outlined
                dense
                v-on="on"
                v-bind="attrs"
                readonly
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.to_date"
              @input="endDate = false"
              :min="form.from_date"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4" class="mt-3">
          <v-btn class="primary" @click="fetchData()">Fetch Data</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row v-if="showChart">
      <template v-for="(d, ind) in dashboard">
        <v-col :cols="d.type === 'linechart' ? 8 : 4" :key="ind">
          <v-card class="pa-4 mt-4 widget-style">
            <h3 class="body-1">{{ d.name }}</h3>
            <DoughnutView v-if="d.type === 'piechart'" :chartData="d.data" />
            <LineChartView v-if="d.type === 'linechart'" :chartData="d.data" />
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-row v-else>
      <v-col class="d-flex justify-center">
        <h2 class="body-1">
          Kindly select from date and to date, To get the results
        </h2>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatDate } from "@/helpers/common";
import { rules } from "@/helpers/formValidation";

import LineChartView from "@/components/dashboard/views/lineChartView";
import DoughnutView from "@/components/dashboard/views/doughnutView";

export default {
  components: {
    LineChartView,
    DoughnutView,
  },
  data() {
    return {
      startDate: false,
      endDate: false,
      form: {
        from_date: "",
        to_date: "",
      },
      rules,
      dashboard: {},
      showChart: false,
      formatDate,
    };
  },
  computed: {
    ...mapState({
      // dashboard: (state) => state.dashboard.dashboard,
    }),
    fromDateFormat() {
      if (this.form.from_date) {
        return this.formatDate(this.form.from_date);
      } else {
        return "";
      }
    },
    toDateFormat() {
      if (this.form.to_date) {
        return this.formatDate(this.form.to_date);
      } else {
        return "";
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("dashboard", ["getDashboardData"]),
    init() {
      //   this.getDashboardData();
    },
    fetchData() {
      if (!this.$refs.form.validate()) return;
      this.getDashboardData(this.form).then((res) => {
        this.dashboard = res;
        this.showChart = true;
      });
    },
  },
};
</script>

<style scoped>
.widget-style {
  padding: 10px;
  height: 350px;
}
</style>
