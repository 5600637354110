<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="8">
            <v-card class="elevation-12">
              <v-tabs-items v-model="step">
                <v-tab-item :value="1">
                  <v-row class="pa-5">
                    <v-col cols="12" md="8">
                      <v-form ref="login">
                        <v-card-text class="mt-5">
                          <h1 class="text-center display-2 accent--text mb-5">
                            Signin to RoloBeat
                          </h1>

                          <v-text-field
                            label="Email"
                            v-model="form.email"
                            prepend-icon="mdi-email"
                            type="text"
                            :rules="[rules.required, rules.email]"
                          />

                          <v-text-field
                            label="Password"
                            v-model="form.password"
                            prepend-icon="mdi-lock"
                            type="password"
                            :rules="[rules.required, rules.password]"
                          />
                        </v-card-text>
                        <div class="text-center mt-3">
                          <v-btn rounded dark color="accent" @click="login"
                            >SIGN IN</v-btn
                          >
                        </div>
                      </v-form>
                    </v-col>
                    <v-col cols="12" md="4" class="primary">
                      <v-card-text class="white--text mt-12">
                        <h1 class="text-center display-1">
                          Don't have an account?
                        </h1>
                        <h3 class="text-center">Please Sign up!</h3>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn rounded outlined dark @click="step++"
                          >SIGN UP</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item :value="2">
                  <v-row class="fill-height pa-5">
                    <v-col cols="12" md="4" class="primary">
                      <v-card-text class="white--text mt-12">
                        <h2 class="text-center">
                          If you already has an account, just sign in
                        </h2>
                      </v-card-text>
                      <div class="text-center mt-12">
                        <v-btn rounded outlined dark @click="step--"
                          >Sign in</v-btn
                        >
                      </div>
                    </v-col>

                    <v-col cols="12" md="8">
                      <v-card-text>
                        <h1 class="text-center display-2 accent--text">
                          Create Account
                        </h1>

                        <v-form ref="signup">
                          <v-row class="d-flex justify-center">
                            <v-col>
                              <v-card flat class="">
                                <div class="text-overline">
                                  License Information
                                </div>
                                <v-divider></v-divider>
                                <v-card-text>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-text-field
                                        v-model.trim="licensee.company_name"
                                        label="Company Name*"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :rules="[
                                          rules.required,
                                          rules.trimWord(
                                            licensee.company_name,
                                            'Company Name'
                                          ),
                                        ]"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <div class="text-overline">
                                  Address Information
                                </div>
                                <v-divider></v-divider>
                                <v-card-text>
                                  <v-row>
                                    <v-col cols="6">
                                      <v-text-field
                                        v-model="licensee.address.address_line1"
                                        label="Address Line 1*"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :rules="[rules.required]"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field
                                        v-model="licensee.address.address_line2"
                                        label="Address Line 2"
                                        outlined
                                        dense
                                        hide-details="auto"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field
                                        v-model="licensee.address.city"
                                        label="City*"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :rules="[rules.required]"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field
                                        v-model="licensee.address.state"
                                        label="State*"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :rules="[rules.required]"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field
                                        v-model="licensee.address.country"
                                        label="Country*"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :rules="[rules.required]"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field
                                        v-model="licensee.address.postcode"
                                        label="Pincode"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        postalcode
                                        :rules="[rules.postalcode]"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <div class="text-overline">
                                  Contact Information
                                </div>
                                <v-divider></v-divider>
                                <v-card-text>
                                  <v-row>
                                    <v-col cols="6">
                                      <v-text-field
                                        v-model="licensee.contact.first_name"
                                        label="First Name*"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :rules="[
                                          rules.required,
                                          rules.trimWord(
                                            licensee.contact.first_name,
                                            'First Name'
                                          ),
                                          rules.alphabet(
                                            licensee.contact.first_name,
                                            'First Name'
                                          ),
                                        ]"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field
                                        v-model="licensee.contact.last_name"
                                        label="Last Name*"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :rules="[
                                          rules.required,
                                          rules.trimWord(
                                            licensee.contact.last_name,
                                            'Last Name'
                                          ),
                                          rules.alphabet(
                                            licensee.contact.last_name,
                                            'Last Name'
                                          ),
                                        ]"
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="6">
                                      <v-text-field
                                        v-model="licensee.contact.email"
                                        label="Email*"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :rules="[rules.required, rules.email]"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field
                                        v-model="licensee.password"
                                        label="Password*"
                                        outlined
                                        dense
                                        type="password"
                                        hide-details="auto"
                                        :rules="[
                                          rules.required,
                                          rules.password,
                                        ]"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field
                                        v-model="licensee.contact.mobile_no"
                                        label="Mobile No*"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :rules="[
                                          rules.required,
                                          rules.mobile,
                                          rules.mobileLength,
                                        ]"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field
                                        v-model="licensee.contact.land_line"
                                        label="Land Line"
                                        outlined
                                        dense
                                        hide-details="auto"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                      <div class="text-center mt-n5">
                        <v-btn rounded color="accent" dark @click="signUp"
                          >SIGN UP</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { rules } from "@/helpers/formValidation";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      rules,
      step: 1,
      licensee: {
        address: {},
        contact: {},
      },
    };
  },
  computed: {
    ...mapState("auth", ["isAuthenticated"]),
  },
  methods: {
    ...mapActions("auth", ["loginUser", "signUpUser"]),
    ...mapMutations("snack", ["showSnackbar"]),

    async login() {
      if (!this.$refs.login.validate()) return;
      const payload = { ...this.form };
      try {
        await this.loginUser(payload);
        this.showSnackbar({
          message: "Logged in Successfully",
        });
        const token = localStorage.getItem("token");
        if (token) this.$router.push("/dashboard");
      } catch (error) {
        this.showSnackbar({
          message: "Invalid credentials",
          color: "error",
        });
      }
    },
    async signUp() {
      if (!this.$refs.signup.validate()) return;
      try {
        await this.signUpUser({ account: this.licensee });
        this.showSnackbar({
          message: "Signed Up Successfully",
        });
        this.step--;
        this.licensee = {
          address: {},
          contact: {},
        };
      } catch (error) {
        this.showSnackbar({
          message: error,
          color: "error",
        });
      }
    },
  },
};
</script>

<style></style>
