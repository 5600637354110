<template>
  <div class="ma-4 pa-4">
    <h2 class="font-weight-medium">TRANSACTION REPORTS</h2>
    <v-form ref="form">
      <v-row>
        <v-col cols="3">
          <v-menu
            v-model="fromDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="formattedStartDate"
                outlined
                dense
                label="From Date*"
                hide-details="auto"
                background-color="bg"
                append-icon="mdi-calendar"
                :rules="[rules.required]"
                v-bind="attrs"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.from_date"
              @input="fromDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="toDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="formattedEndDate"
                label="To Date*"
                outlined
                dense
                :placeholder="
                  !search.from_date ? 'choose a from date first' : ''
                "
                hide-details="auto"
                background-color="bg"
                append-icon="mdi-calendar"
                :disabled="!search.from_date"
                :rules="[rules.required]"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.to_date"
              :min="getEndDate()"
              @input="toDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="search.store_id"
            label="Store"
            outlined
            dense
            :items="stores"
            item-text="name"
            item-value="id"
            background-color="bg"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="search.user_id"
            label="User"
            outlined
            dense
            :items="usersList"
            item-text="name"
            item-value="id"
            background-color="bg"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>

        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end pt-3">
          <v-btn
            rounded
            depressed
            outlined
            color="accent"
            class="mr-5"
            :disabled="!search.from_date || !search.to_date"
            @click="onGenerateReport"
          >
            <v-icon class="mr-1">mdi-folder-text-outline</v-icon>
            Generate
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            color="accent"
            @click="onClearFilter"
          >
            <v-icon class="mr-1">mdi-close</v-icon>
            Clear
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col cols="12">
        <v-sheet v-if="valid" outlined>
          <v-data-table
            :headers="transactionsHeader"
            :items="transactions"
            fixed-header
          >
            <template #[`item.date_time`]="{ value }">
              {{ formatDateTime(value) }}
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { transactionsHeader } from "@/helpers/tableHeaders.js";
import { rules } from "@/helpers/formValidation";
import { formatDateTime, formatDate } from "@/helpers/common";

export default {
  components: {},
  data() {
    return {
      search: {
        store_id: null,
        from_date: null,
        to_date: null,
        user_id: null,
      },
      fromDate: false,
      toDate: false,
      formattedStartDate: null,
      formattedEndDate: null,
      transactionsHeader,
      valid: false,
      rules,
      formatDateTime,
      formatDate,
    };
  },
  computed: {
    ...mapState({
      transactions: (state) => state.report.transactionReports,
      usersList: (state) => state.user.usersList,
      stores: (state) => state.site.siteList,
    }),
  },
  watch: {
    "search.from_date"(v) {
      this.formattedStartDate = v ? this.formatDate(v) : null;
    },
    "search.to_date"(v) {
      this.formattedEndDate = v ? this.formatDate(v) : null;
    },
    search: {
      handler() {
        this.valid = false;
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("report", ["getTransactionReports"]),
    ...mapActions("user", ["getUsers"]),
    ...mapActions("site", ["getSites"]),

    init() {
      this.getUsers();
      this.getSites();
    },
    getEndDate() {
      if (this.search.to_date && this.search.from_date > this.search.to_date) {
        this.search.to_date = null;
        this.toDate = "";
        return;
      }
      return this.search.from_date;
    },
    onGenerateReport() {
      if (this.$refs.form.validate()) {
        const search = this.objectToQueryString(this.search);
        this.getTransactionReports(search).then(() => {
          this.valid = true;
        });
      } else {
        this.$notifier.showMessage({
          msg: "Please correct the error(s) in the form to proceed further",
          color: "error",
        });
      }
    },
    objectToQueryString(obj) {
      const keyValuePairs = [];
      for (const key in obj) {
        if (obj[key] !== null) {
          keyValuePairs.push(
            encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
          );
        }
      }
      return "?" + keyValuePairs.join("&");
    },
    onClearFilter() {
      this.search = {
        store_id: null,
        from_date: null,
        to_date: null,
        user_id: null,
      };
      this.valid = false;
      this.fromDate = "";
      this.toDate = "";
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
