import axios from "@/plugins/axios";
import { throwError } from "@/helpers/error";

const state = {
  questionnaireList: [],
  questionnaireListByStore: [],
  auditSchedulers: [],
  adhocSchedulers: [],
  filter: "",
  auditSchedulerFilter: "",
  adhocSchedulerFilter: "",
};

const mutations = {
  setQuestionnaireList(state, list) {
    state.questionnaireList = list;
  },
  setQuestionnaireListByStore(state, list) {
    state.questionnaireListByStore = list;
  },
  setAuditSchedulers(state, list) {
    state.auditSchedulers = list;
  },
  setAdhocSchedulers(state, list) {
    state.adhocSchedulers = list;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  setAuditSchedulerFilter(state, filter) {
    state.auditSchedulerFilter = filter;
  },
  setAdhocSchedulerFilter(state, filter) {
    state.adhocSchedulerFilter = filter;
  },
  resetState: (state) => {
    state.questionnaireList = [];
    state.questionnaireListByStore = [];
    state.auditSchedulers = [];
    state.adhocSchedulers = [];
    state.filter = "";
    state.auditSchedulerFilter = "";
    state.adhocSchedulerFilter = "";
  },
};

const actions = {
  async getQuestionnaires(
    { commit },
    { ids, is_published = false, active = false } = {}
  ) {
    let url = "/audits";
    if (ids && ids.length) url = `${url}?department_ids=${ids}`;
    if (state.filter) url = `${url}?active=${state.filter}`;
    if (is_published)
      url = `${url}${
        ids && ids.length ? "&" : "?"
      }is_published=${is_published}`;
    if (active) url = `${url}&active=${active}`;

    try {
      const res = await axios.get(url);
      commit("setQuestionnaireList", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async createQuestionnaire({ dispatch }, payload) {
    try {
      const res = await axios.post("/audits/", {
        audit: payload,
      });
      dispatch("getQuestionnaires");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async updateQuestionnaire({ dispatch }, payload) {
    try {
      const res = await axios.put(`/audits/${payload.id}`, {
        audit: payload,
      });
      dispatch("getQuestionnaires");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async deleteQuestionnaire({ dispatch }, { active, id }) {
    const data = { active: !active };
    try {
      const res = await axios.put(`/audits/${id}`, {
        audit: data,
      });
      dispatch("getQuestionnaires");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getAuditByStore({ commit }, id) {
    let url = `/audits/${id}/store`;
    try {
      const res = await axios.get(url);
      commit("setQuestionnaireListByStore", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getAuditSchedulers({ commit }) {
    let url = "/workorder_schedulers?active=true";
    try {
      const res = await axios.get(url);
      commit("setAuditSchedulers", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getAdhocSchedulers({ commit }) {
    let url = "/workorder_adhoc_schedules?active=true";
    try {
      const res = await axios.get(url);
      commit("setAdhocSchedulers", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async createAuditScheduler({ dispatch }, payload) {
    try {
      const res = await axios.post("/workorder_schedulers", {
        workorder_scheduler: payload,
      });
      dispatch("getAuditSchedulers");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async createAdhocScheduler({ dispatch }, payload) {
    try {
      const res = await axios.post("/workorder_adhoc_schedules", {
        workorder_adhoc_schedule: payload,
      });
      dispatch("getAdhocSchedulers");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async deleteAuditScheduler({ dispatch }, { active, id }) {
    try {
      const data = { active: !active };
      const res = await axios.put(`/workorder_schedulers/${id}`, {
        workorder_scheduler: data,
      });
      dispatch("getAuditSchedulers");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async deleteAdhocScheduler({ dispatch }, { active, id }) {
    try {
      const data = { active: !active };
      const res = await axios.put(`/workorder_adhoc_schedules/${id}`, {
        workorder_adhoc_schedule: data,
      });
      dispatch("getAdhocSchedulers");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
