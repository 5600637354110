import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#2296F3",
        secondary: "#FFF",
        accent: "#2296F3",
        error: "#b71c1c",
        accent2: "#E3F2FD",
        snackbar: "#708090",
      },
    },
  },
});
