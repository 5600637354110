<template>
  <v-card>
    <v-form ref="form" v-model="formValid" :readonly="form.is_published">
      <v-card-title class="font-weight-bold white--text primary">
        {{ form.is_published ? "View" : edit ? "Edit" : "Add" }} Audit
        <v-spacer></v-spacer>
        <v-btn
          class="d-flex justify-end"
          icon
          plain
          @click="$emit('closeDialog')"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-text-field
          v-model="form.title"
          label="Title*"
          outlined
          dense
          :rules="[rules.required]"
        />
        <v-autocomplete
          v-model="form.department_ids"
          label="Departments*"
          :items="departments"
          item-text="name"
          item-value="id"
          multiple
          deletable-chips
          small-chips
          outlined
          dense
          attach
          :rules="[rules.required, rules.getLength]"
        />
        <v-expansion-panels v-model="sectionActive">
          <vuedraggable v-model="form.sections" style="width: 100%">
            <v-expansion-panel
              v-for="(detail, dIdx) in form.sections"
              :key="dIdx"
              class="my-4"
              @click="activePanel = 0"
            >
              <!-- style="background: #f2f5f6" -->
              <div v-if="detail.active">
                <v-expansion-panel-header expand-icon="mdi-menu-down">
                  {{ detail.name ? `${dIdx + 1 + ". " + detail.name}` : "" }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field
                    v-model="detail.name"
                    label="Section name*"
                    outlined
                    dense
                    :rules="[rules.required]"
                  />
                  <v-expansion-panels v-model="activePanel">
                    <vuedraggable
                      v-model="detail.questions"
                      style="width: 100%"
                    >
                      <v-expansion-panel
                        v-for="(question, qIdx) in detail.questions"
                        :key="question.id"
                        class="my-4"
                      >
                        <div v-if="question.active">
                          <v-expansion-panel-header expand-icon="mdi-menu-down">
                            {{
                              question.name
                                ? `${qIdx + 1 + ". " + question.name}`
                                : ""
                            }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row>
                              <v-col cols="9">
                                <v-textarea
                                  v-model="question.name"
                                  label="Question*"
                                  outlined
                                  dense
                                  rows="2"
                                  :rules="[rules.required]"
                                  auto-grow
                                />
                              </v-col>
                              <v-col cols="3">
                                <v-select
                                  v-model="question.type"
                                  :items="answerType"
                                  item-text="text"
                                  item-value="id"
                                  outlined
                                  dense
                                  @change="changeAnswerType($event, dIdx, qIdx)"
                                ></v-select>
                              </v-col>
                              <v-col v-if="question.type === 1" cols="12">
                                <v-row
                                  v-for="(binary, idx) in question.options"
                                  :key="binary.name"
                                  justify="center"
                                >
                                  <v-col cols="12" class="d-flex align-center">
                                    <v-col cols="8" class="py-0">
                                      <v-text-field
                                        v-model.trim="binary.answer_label"
                                        dense
                                        outlined
                                        :placeholder="
                                          binary.name === 'Green' ? 'Yes' : 'No'
                                        "
                                        hide-details="auto"
                                        :rules="[
                                          rules.required,
                                          rules.trimWord,
                                          (v) =>
                                            rules.isDiffer(
                                              question.options,
                                              idx,
                                              v
                                            ),
                                        ]"
                                      />
                                    </v-col>
                                    <v-col cols="2" class="py-0">
                                      <div
                                        class="text-h6 px-2"
                                        :class="
                                          binary.name === 'Green'
                                            ? 'green--text'
                                            : 'red--text'
                                        "
                                        style="width: 100px"
                                      >
                                        <v-icon small :color="binary.color"
                                          >mdi-circle</v-icon
                                        >
                                        <span class="subtitle-1 px-2">{{
                                          binary.name
                                        }}</span>
                                      </div>
                                    </v-col>
                                    <v-col cols="2" class="py-0">
                                      <v-checkbox
                                        v-if="binary.color !== 'green'"
                                        label="Raise issue"
                                        v-model="binary.raise_issue"
                                        hide-details
                                        class="mt-n1"
                                        :disabled="!question.is_required"
                                      />
                                    </v-col>
                                  </v-col>
                                </v-row>
                              </v-col>

                              <v-col v-if="question.type === 2" cols="12">
                                <v-row
                                  v-for="number in question.options"
                                  :key="number.color"
                                  justify="center"
                                >
                                  <v-col cols="12" class="d-flex align-center">
                                    <v-col cols="4" class="py-0">
                                      <v-text-field
                                        v-model="number.min"
                                        dense
                                        outlined
                                        placeholder="Min"
                                        :rules="[
                                          number.name === 'Amber' && !number.max
                                            ? true
                                            : rules.required,
                                          rules.greater(number.min, number.max),
                                          number.errors || true,
                                        ]"
                                        class="mr-2"
                                      />
                                    </v-col>
                                    <v-col cols="4" class="py-0">
                                      <v-text-field
                                        v-model="number.max"
                                        dense
                                        outlined
                                        placeholder="Max"
                                        :rules="[
                                          number.name === 'Amber' && !number.min
                                            ? true
                                            : rules.required,
                                          rules.greater(number.min, number.max),
                                          number.errors || true,
                                        ]"
                                      />
                                    </v-col>
                                    <v-col cols="2" class="py-0">
                                      <div
                                        class="text-h6 px-2 mt-n7"
                                        :class="
                                          number.name === 'Green'
                                            ? 'green--text'
                                            : number.name === 'Amber'
                                            ? 'amber--text'
                                            : number.name === 'Red'
                                            ? 'red--text'
                                            : ''
                                        "
                                        style="width: 100px"
                                      >
                                        <v-icon small :color="number.color"
                                          >mdi-circle</v-icon
                                        >
                                        <span class="subtitle-1 px-2">{{
                                          number.name
                                        }}</span>
                                      </div>
                                    </v-col>
                                    <v-col cols="2" class="py-0">
                                      <v-checkbox
                                        v-if="number.color !== 'green'"
                                        label="Raise issue"
                                        v-model="number.raise_issue"
                                        hide-details
                                        class="mt-n7"
                                        :disabled="!question.is_required"
                                      />
                                    </v-col>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col v-if="question.type === 3" cols="12">
                                <v-row
                                  v-for="(multi, index) in question.options"
                                  :key="index"
                                  justify="center"
                                >
                                  <v-col cols="12" class="d-flex align-center">
                                    <v-col cols="2" class="py-0">
                                      <v-select
                                        v-model="multi.color"
                                        :items="multiOption"
                                        item-text="color"
                                        item-value="color"
                                        dense
                                        outlined
                                        hide-details="auto"
                                      >
                                        <template #[`item`]="{ item }">
                                          <div
                                            :style="{
                                              backgroundColor:
                                                item.color === 'amber'
                                                  ? '#FFBF00'
                                                  : item.color,
                                              width: '20px',
                                              height: '20px',
                                            }"
                                          ></div>
                                        </template>
                                        <template #[`selection`]="{ item }">
                                          <div
                                            :style="{
                                              backgroundColor:
                                                item.color === 'amber'
                                                  ? '#FFBF00'
                                                  : item.color,
                                              width: '20px',
                                              height: '20px',
                                            }"
                                          ></div>
                                        </template>
                                      </v-select>
                                    </v-col>
                                    <v-col cols="7" class="py-0">
                                      <v-text-field
                                        v-model.trim="multi.answer_label"
                                        dense
                                        outlined
                                        placeholder="Answer label"
                                        hide-details="auto"
                                        :rules="[
                                          rules.required,
                                          rules.trimWord,
                                          (v) =>
                                            rules.isDiffer(
                                              question.options,
                                              index,
                                              v
                                            ),
                                        ]"
                                      />
                                    </v-col>
                                    <v-col cols="2">
                                      <v-checkbox
                                        v-if="multi.color !== 'green'"
                                        label="Raise issue"
                                        v-model="multi.raise_issue"
                                        hide-details
                                        class="mt-n1"
                                        :disabled="!question.is_required"
                                      />
                                    </v-col>

                                    <v-col cols="1" class="py-0">
                                      <v-btn
                                        v-if="
                                          question.options.length > 1 &&
                                          !form.is_published
                                        "
                                        icon
                                        @click="
                                          removeMultiChoice(dIdx, qIdx, index)
                                        "
                                        ><v-icon color="red">mdi-delete</v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-col>
                                </v-row>
                                <v-btn
                                  v-if="!form.is_published"
                                  rounded
                                  outlined
                                  depressed
                                  color="accent"
                                  class="mt-2"
                                  @click="addMultiChoice(dIdx, qIdx)"
                                  ><v-icon small>mdi-plus</v-icon>Add
                                  Choice</v-btn
                                >
                              </v-col>

                              <v-col
                                cols="12"
                                class="d-flex justify-end align-center"
                              >
                                <!-- <v-checkbox
                                  v-if="question.type === 1"
                                  label="Raise issue"
                                  v-model="question.raise_issue"
                                  :disabled="!question.is_required"
                                /> -->
                                <v-checkbox
                                  label="Attachments"
                                  v-model="question.is_attachment_required"
                                  :disabled="!question.is_required"
                                  class="mx-3"
                                />
                                <v-checkbox
                                  label="Required"
                                  v-model="question.is_required"
                                  @change="
                                    !question.is_required
                                      ? (changeCheckBoxValue(question),
                                        (question.is_attachment_required = false))
                                      : ''
                                  "
                                />
                                <v-btn
                                  v-if="!form.is_published"
                                  icon
                                  @click="
                                    deleteConfirmDialog('question', dIdx, qIdx)
                                  "
                                  ><v-icon color="red"
                                    >mdi-delete</v-icon
                                  ></v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </div>
                      </v-expansion-panel>
                    </vuedraggable>
                  </v-expansion-panels>
                  <v-col class="d-flex">
                    <v-btn
                      v-if="!form.is_published"
                      :disabled="!formValid"
                      rounded
                      outlined
                      depressed
                      color="accent"
                      @click="addQuestion(dIdx)"
                      >Add Question</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="form.sections.length > 1 && !form.is_published"
                      rounded
                      outlined
                      depressed
                      color="accent"
                      @click="deleteConfirmDialog('section', dIdx)"
                      ><v-icon color="red">mdi-delete</v-icon>Delete
                      Section</v-btn
                    >
                  </v-col>
                </v-expansion-panel-content>
              </div>
            </v-expansion-panel>
          </vuedraggable>
        </v-expansion-panels>
        <v-row>
          <v-col class="d-flex justify-start">
            <v-btn
              v-if="!form.is_published"
              :disabled="!formValid"
              rounded
              outlined
              depressed
              color="accent"
              @click="addCategory()"
              >Add Section</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!form.is_published">
        <v-spacer></v-spacer>
        <v-btn rounded depressed outlined color="accent" @click="save(false)">{{
          edit ? "Update" : "Save"
        }}</v-btn>
        <v-btn rounded depressed outlined color="accent" @click="save(true)">{{
          edit ? "Update & Publish" : "Save & Publish"
        }}</v-btn>
      </v-card-actions>
    </v-form>
    <confirmDialogBox
      v-if="deleteDialog"
      :show="deleteDialog"
      title="Delete"
      message1="Are you sure want to delete"
      :message2="message"
      @sure="removeSectionQuestion"
      @close="deleteDialog = false"
    />
  </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import draggable from "vuedraggable";
import { rules } from "@/helpers/formValidation";
import confirmDialogBox from "@/components/confirmDialog.vue";

export default {
  props: {
    questionnaire: {
      type: Object,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    vuedraggable: draggable,
    confirmDialogBox,
  },
  data() {
    return {
      formValid: false,
      deleteDialog: false,
      questionIndex: 0,
      sectionIndex: 0,
      message: "",
      form: {
        title: "",
        department_ids: [],
        sections: [
          {
            name: "",
            sequence_number: null,
            active: true,
            questions: [
              {
                name: "",
                type: 1,
                is_required: true,
                is_attachment_required: false,
                raise_issue: false,
                sequence_number: null,
                active: true,
                options: [
                  {
                    name: "Green",
                    color: "green",
                    answer_label: "Yes",
                    raise_issue: false,
                  },
                  {
                    name: "Red",
                    color: "red",
                    answer_label: "No",
                    raise_issue: false,
                  },
                ],
              },
            ],
          },
        ],
      },
      types: [
        {
          type: 1,
          options: [
            {
              name: "Green",
              color: "green",
              answer_label: "Yes",
              raise_issue: false,
            },
            {
              name: "Red",
              color: "red",
              answer_label: "No",
              raise_issue: false,
            },
          ],
        },
        {
          type: 2,
          options: [
            {
              name: "Green",
              color: "green",
              min: "",
              max: "",
              raise_issue: false,
            },
            { name: "Red", color: "red", min: "", max: "", raise_issue: false },
            {
              name: "Amber",
              color: "amber",
              min: "",
              max: "",
              raise_issue: false,
            },
          ],
        },
        {
          type: 3,
          options: [
            { color: "green", answer_label: "", raise_issue: false },
            { color: "red", answer_label: "", raise_issue: false },
          ],
        },
        {
          type: 4,
        },
        {
          type: 5,
        },
      ],

      rules,

      answerType: [
        { id: 1, text: "Yes/No" },
        { id: 2, text: "Range" },
        { id: 3, text: "Multi Choice" },
        { id: 4, text: "Number" },
        { id: 5, text: "Text" },
      ],
      multiOption: [
        {
          id: 1,
          color: "green",
          raise_issue: false,
        },
        {
          id: 2,
          color: "red",
          raise_issue: false,
        },
        {
          id: 3,
          color: "amber",
          raise_issue: false,
        },
      ],
      activePanel: 0,
      sectionActive: 0,
    };
  },
  watch: {
    form: {
      handler() {
        this.isAllNumberQuestionsValid();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      departments: (state) => state.department.departmentList,
    }),
  },
  created() {
    this.init();
    this.getDepartments();
  },
  methods: {
    ...mapActions("department", ["getDepartments"]),
    ...mapMutations("snack", ["showSnackbar"]),

    findAnswerByType(type) {
      const res = this.types.find((item) => item.type === type);
      return JSON.parse(JSON.stringify(res))?.options;
    },
    init() {
      if (this.edit) {
        this.form = { ...this.questionnaire };
        const sections = this.form.sections;
        this.sectionActive = sections.length - 1;
        this.activePanel = sections[sections.length - 1].questions.length - 1;
      }
    },
    showErrors(categoryName, question) {
      this.showSnackbar({
        message: `Question ${
          question + 1
        } in category "${categoryName}" is invalid!`,
        color: "error",
      });
    },
    save(is_published) {
      if (!this.$refs.form.validate() || !this.isAllNumberQuestionsValid())
        return;
      this.form.sections.forEach((d, ind) => {
        d.sequence_number = ind + 1;
        d.questions.forEach((q, idx) => {
          q.sequence_number = idx + 1;
        });
      });
      this.$emit("save", { ...this.form, is_published: is_published });
    },
    addQuestion(dIdx) {
      if (!this.isAllNumberQuestionsValid()) return;
      this.form.sections[dIdx].questions.push({
        name: "",
        type: 1,
        active: true,
        is_required: true,
        is_attachment_required: false,
        raise_issue: false,
        options: this.findAnswerByType(1),
      });
      this.activePanel = this.form.sections[dIdx].questions.length - 1;
    },
    removeQuestion(dIdx, qIdx) {
      const questions = this.form.sections[dIdx].questions;
      const question = questions[qIdx];

      if (question.id) question.active = false;
      else questions.splice(qIdx, 1);

      this.activePanel = ++qIdx;
    },
    addMultiChoice(dIdx, qIdx) {
      this.form.sections[dIdx].questions[qIdx].options.push({
        id: this.generateUniqueNumericID(),
        color: "green",
        answer_label: "",
        raise_issue: false,
      });
    },
    removeMultiChoice(dIdx, qIdx, index) {
      this.form.sections[dIdx].questions[qIdx].options.splice(index, 1);
    },
    changeAnswerType(e, dIdx, qIdx) {
      this.form.sections[dIdx].questions[qIdx].options =
        this.findAnswerByType(e);
    },
    addCategory() {
      if (!this.isAllNumberQuestionsValid()) return;
      this.form.sections.push({
        active: true,
        questions: [
          {
            name: "",
            type: 1,
            active: true,
            is_required: true,
            is_attachment_required: false,
            raise_issue: false,
            options: [
              {
                name: "Green",
                color: "green",
                answer_label: "Yes",
                raise_issue: false,
              },
              {
                name: "Red",
                color: "red",
                answer_label: "No",
                raise_issue: false,
              },
            ],
          },
        ],
      });
      this.sectionActive = this.form.sections.length - 1;
      this.activePanel = 0;
    },
    removeCategory(dIdx) {
      if (this.form.sections[dIdx].id) this.form.sections[dIdx].active = false;
      else this.form.sections.splice(dIdx, 1);
      this.sectionActive = ++dIdx;
    },

    generateUniqueNumericID() {
      const timestamp = Date.now();
      const uniqueID = timestamp * 1000;
      return uniqueID;
    },
    isAllNumberQuestionsValid() {
      const result = this.listAllNumberQuestions().filter(
        ({ question, category }) =>
          !this.isNumberQuestionValid(category, question)
      );
      this.$forceUpdate();
      if (result.length) {
        // const { name, question } = result[0];
        // this.showErrors(name, question);
        return false;
      }
      return true;
    },
    listAllNumberQuestions() {
      // Filter all number questions and adding category and question ids
      const list = this.form.sections.flatMap((detail, dId) =>
        detail.questions.reduce((acc, ques, qId) => {
          const obj = { ...ques };
          if (ques.type === 2) {
            obj.category = dId;
            obj.question = qId;
            acc.push(obj);
          }
          return acc;
        }, [])
      );
      return list;
    },
    isNumberQuestionValid(cId, qId) {
      const lastCategory = this.form.sections[cId];
      const lastQuestion = lastCategory?.questions[qId];
      if (lastQuestion?.type !== 2) return true;

      // amber not given
      const list = lastQuestion?.options.filter((i) => {
        return i.min && i.max;
      });
      if (this.isIndividualItemValid(list))
        return !this.anyRangeIntersects(list);
      return false;
    },
    isIndividualItemValid(arr) {
      return arr.every((item) => Number(item.min) < Number(item.max));
    },
    anyRangeIntersects(arr) {
      for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (this.doRangesIntersect(arr[i], arr[j])) {
            arr[i].errors = `Range overlapping with ${arr[j].name}`;
            arr[j].errors = `Range overlapping with ${arr[i].name}`;
            return true;
          } else {
            arr[i].errors = null;
            arr[j].errors = null;
          }
        }
      }
      return false;
    },
    doRangesIntersect(range1, range2) {
      return (
        (Number(range1.min) <= Number(range2.max) &&
          Number(range1.max) >= Number(range2.min)) ||
        (Number(range2.min) <= Number(range1.max) &&
          Number(range2.max) >= Number(range1.min))
      );
    },
    changeCheckBoxValue(question) {
      question.options.forEach((i) => (i.raise_issue = false));
    },
    removeSectionQuestion() {
      if (this.message === "the question") {
        this.removeQuestion(this.sectionIndex, this.questionIndex);
      } else if (this.message === "the section") {
        this.removeCategory(this.sectionIndex);
      }
      this.deleteDialog = false;
    },
    deleteConfirmDialog(message, sectionIndex, questionIndex) {
      this.sectionIndex = sectionIndex;
      this.questionIndex = questionIndex;
      this.message = message;
      this.deleteDialog = true;
    },
  },
};
</script>

<style scoped>
.circle {
  height: 10px;
  width: 10px;
  background: green;
}
</style>
