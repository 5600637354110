export const throwError = (response) => {
  let errors = response.data.errors;
  if (!errors) {
    throw response.statusText;
  }
  if (Array.isArray(errors)) errors = errors[0];
  const [[key, [value]]] = Object.entries(errors);
  let splitArray = key.split("_");
  let joinedKeyString = splitArray.join(" ");
  throw `${joinedKeyString} ${value}`;
};
