<template>
  <v-card>
    <v-form ref="form">
      <v-card-title class="font-weight-bold white--text primary">
        Inventory Scheduler
        <v-spacer></v-spacer>
        <v-btn
          class="d-flex justify-end"
          icon
          plain
          @click="(form = {}), $emit('closeDialog')"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="form.store_id"
              :items="storeList"
              label="Store*"
              item-text="name"
              item-value="id"
              hide-details="auto"
              hide-selected
              outlined
              dense
              :rules="[rules.required]"
              :readonly="!!scheduler"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="form.user_id"
              hide-details="auto"
              :items="auditorList"
              label="Audit User*"
              item-text="name"
              item-value="id"
              outlined
              dense
              :rules="[rules.required]"
              :readonly="!!scheduler"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="form.frequency"
              hide-details="auto"
              :items="frequencyTypes"
              label="Repeat*"
              item-text="name"
              item-value="key"
              outlined
              dense
              :readonly="!!scheduler"
              :rules="[rules.required]"
              :suffix="
                form.frequency === 'W'
                  ? getDayOfTheWeek(form.first_occurrence_date)
                  : ''
              "
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              ref="expire"
              v-model="form.estimation_time"
              hide-details="auto"
              label="Expire in*"
              outlined
              dense
              type="number"
              :readonly="!!scheduler"
              :rules="[
                rules.required,
                rules.naturalNumber,
                (v) => rules.isExpired(v, form.frequency),
              ]"
              suffix="mins"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.prior_time"
              :readonly="!!scheduler"
              hide-details="auto"
              label="Notify before*"
              outlined
              dense
              suffix="mins"
              type="number"
              :rules="[rules.required, rules.naturalNumber, rules.maxMinute]"
            ></v-text-field>
          </v-col>

          <v-col cols="6" class="py-3">
            <v-menu
              v-model="startDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="form.first_occurrence_date"
                  hide-details="auto"
                  label="First Occurrence Date*"
                  outlined
                  dense
                  v-on="on"
                  v-bind="attrs"
                  readonly
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-if="!scheduler"
                v-model="form.first_occurrence_date"
                :min="new Date().toISOString().substring(0, 10)"
                @input="startDate = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" class="py-3">
            <v-menu
              ref="menu1"
              v-model="startTime"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="form.first_occurrence_time"
                  hide-details="auto"
                  label="First Occurrence Time*"
                  outlined
                  dense
                  v-on="on"
                  v-bind="attrs"
                  readonly
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="!scheduler"
                v-model="form.first_occurrence_time"
                :readonly="!!scheduler"
                ampm-in-title
                full-width
                :min="futureTime"
                @click:minute="$refs.menu1.save(form.first_occurrence_time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!!scheduler"
          rounded
          depressed
          outlined
          color="accent"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { rules } from "@/helpers/formValidation";
import { getDayOfTheWeek } from "@/helpers/common";
import { frequencyTypes } from "@/helpers/commonDatas";

export default {
  props: {
    scheduler: {
      type: Object,
      default: () => null,
    },
    storeList: {
      type: Array,
      default: () => [],
    },
    auditorList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {},
      rules,
      startTime: "",
      startDate: "",
      frequencyTypes,
      getDayOfTheWeek,
    };
  },
  computed: {
    currentTime() {
      const currentTime = new Date();
      const hours = currentTime.getHours().toString().padStart(2, "0");
      const minutes = currentTime.getMinutes().toString().padStart(2, "0");
      const currentTimeString = `${hours}:${minutes}`;
      return currentTimeString;
    },
    futureTime() {
      return new Date().toISOString().substring(0, 10) ===
        this.form.first_occurrence_date
        ? this.currentTime
        : "";
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.scheduler) this.form = { ...this.scheduler };
    },
    save() {
      if (!this.$refs.form.validate()) return;
      const payload = { ...this.form };
      payload.first_occurrence_time = `${this.form.first_occurrence_time}:00`;
      this.$emit("save", payload);
    },
  },
};
</script>

<style></style>
