<template>
  <v-container>
    <v-row>
      <v-col cols="5">
        <v-col>
          <v-select
            v-model="selectedYear"
            :items="years"
            label="Select Year"
            @change="updateSelectedYear"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col>
          <v-date-picker
            v-model="selectedDatesArray"
            @input="updateSelectedDates"
            multiple
            no-title
            :min="minDate()"
            :max="maxDate()"
            :picker-date="computedPickerDate"
            @update:picker-date="handlePickerDateUpdate"
          ></v-date-picker>
        </v-col>
      </v-col>

      <v-col>
        <v-row>
          <v-col>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Month</th>
                    <th class="text-left">Dates</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(month, ind) in filterYear" :key="ind">
                    <td>{{ months[ind] }}</td>
                    <td>{{ joinedDates(month) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedYear: null,
      selectedDatesArray: [],
      selectedDates: {},
      years: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      internalPickerDate: ``,
      currentDate: "",
    };
  },
  watch: {
    selectedDatesArray: {
      handler(v) {
        this.$emit("valueChanged", v);
      },
      deep: true,
    },
  },
  computed: {
    computedPickerDate() {
      return this.internalPickerDate;
    },
    filterYear() {
      return this.selectedDates[this.selectedYear];
    },
  },
   created() {
    const date = new Date();
    this.currentYear = date.getFullYear();
    const startYear = 2024;
    const yearDifference = this.currentYear - startYear;
    const yearsToAdd = 5;

    for (let i = 0; i < yearDifference + yearsToAdd; i++) {
      this.years.push(startYear + i);
    }
    this.init();
  },

  methods: {
    init() {
      this.selectedDatesArray = [...this.data];
      this.selectedYear = this.years[0];
      this.updateSelectedDates();
      this.internalPickerDate = `${this.selectedYear}-01`;
    },
    joinedDates(month) {
      // Join the array elements with commas
      const date = month.map((date) => date.slice(-2)).sort();
      return date.join(", ");
    },

    minDate() {
      if (this.currentYear == this.selectedYear) {
        return new Date().toISOString().substring(0, 10);
      }
      return `${this.selectedYear}-01-01`;
    },
    maxDate() {
      return `${this.selectedYear}-12-31`;
    },
    updateSelectedDates() {
      this.selectedDates = {};

      for (const date of this.selectedDatesArray) {
        const year = new Date(date).getFullYear();
        const month = new Date(date).getMonth();

        if (!this.selectedDates[year]) {
          this.selectedDates[year] = {};
        }

        if (!this.selectedDates[year][month]) {
          this.selectedDates[year][month] = [];
        }

        this.selectedDates[year][month].push(date);
      }
      return this.selectedDates;
    },
    updateSelectedYear() {
      this.internalPickerDate = `${this.selectedYear}-01`;

      // Additional logic if needed when the year changes
      // For example, you might want to fetch data based on the new year
    },
    handlePickerDateUpdate(e) {
      this.internalPickerDate = e;
    },
  },
};
</script>

<style>
/* Add your custom styles here */
</style>
