import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Site from "../views/basic/site.vue";
import User from "../views/basic/user.vue";
import Department from "../views/basic/department.vue";
import Login from "../views/login.vue";
import Questionnaires from "../views/audit/questionnaires.vue";
import AuditReport from "../views/report/auditReport.vue";
import Dashboard from "../views/dashboard.vue";
import IssueCategory from "../views/issue/IssueCategory.vue";
import InventoryItem from "../views/inventory/InventoryItem.vue";
import Stock from "../views/inventory/stock.vue";
import AuditScheduler from "../views/scheduler/auditScheduler.vue";
import AdhocScheduler from "../views/scheduler/adhocScheduler.vue";
import InventoryScheduler from "../views/scheduler/inventoryScheduler.vue";
import TransactionReport from "../views/report/transactionReport.vue";
import IssueListReport from "../views/report/issueListReport.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      auth: false,
    },
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: "/login",
    meta: {
      auth: true,
    },

    children: [
      {
        path: "/store",
        name: "store",
        component: Site,
        meta: {
          auth: true,
        },
      },
      {
        path: "/user",
        name: "user",
        component: User,
        meta: {
          auth: true,
        },
      },
      {
        path: "/department",
        name: "department",
        component: Department,
        meta: {
          auth: true,
        },
      },
      {
        path: "/audits",
        name: "Questionnaires",
        component: Questionnaires,
        meta: {
          auth: true,
        },
      },
      {
        path: "/auditReport",
        name: "auditReport",
        component: AuditReport,
        meta: {
          auth: true,
        },
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
          auth: true,
        },
      },
      {
        path: "/issuecategory",
        name: "issueCategory",
        component: IssueCategory,
        meta: {
          auth: true,
        },
      },
      {
        path: "/inventoryitem",
        name: "inventoryItem",
        component: InventoryItem,
        meta: {
          auth: true,
        },
      },
      {
        path: "/stock",
        name: "stock",
        component: Stock,
        meta: {
          auth: true,
        },
      },
      {
        path: "/auditscheduler",
        name: "auditScheduler",
        component: AuditScheduler,
        meta: {
          auth: true,
        },
      },
      {
        path: "/adhocscheduler",
        name: "adhocScheduler",
        component: AdhocScheduler,
        meta: {
          auth: true,
        },
      },
      {
        path: "/invscheduler",
        name: "inventoryScheduler",
        component: InventoryScheduler,
        meta: {
          auth: true,
        },
      },
      {
        path: "/transactionReport",
        name: "transactionReport",
        component: TransactionReport,
        meta: {
          auth: true,
        },
      },
      {
        path: "/issueListReport",
        name: "issueListReport",
        component: IssueListReport,
        meta: {
          auth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

/* to resolve error: Avoided redundant navigation to current location */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  var authRequired = to.matched.some((route) => route.meta.auth);
  let token = localStorage.getItem("token");
  if (authRequired && !token) {
    next("/login");
    return;
  }
  next();
});

export default router;
