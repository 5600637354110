export const rules = {
  required: (v) => !!v || "This field is required",
  email: (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "Enter a valid email",
  password: (v) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/.test(v) ||
    "Enter a valid password",

  float: (v) => !v || /^\d+(\.\d+)?$/.test(v) || "Enter a valid decimal number",
  number: (v) =>
    !v || (v >= 0 && /^-?\d*\.?\d+$|^-?$/.test(v)) || "Enter a valid number",
  naturalNumber: (v) =>
    !v ||
    (Number(v) > 0 && Number.isInteger(Number(v))) ||
    "Enter a valid number",
  getLength: (v) => !v || v.length > 0 || `This field is required`,
  trimWord: (v, param) => {
    return (!!v?.length && !!v?.trim().length) || `Enter a valid ${param}`;
  },
  mobile: (v) => !v || /^(\d+)$/.test(v) || "Enter a valid mobile number",
  mobileLength: (v) => v?.length === 10 || "Enter a 10 digit mobile number",
  postalcode: (v) =>
    !v || (/^\d+$/.test(v) && v > 99999 && v <= 999999) || "Enter a valid code",
  // isDiffer: (list, index) => {
  //   const arr = list.map((i) => i.answer_label.trim().toLowerCase());
  //   if (arr.includes("") && arr[index] !== "") return true;
  //   const items = [...new Set(arr)];
  //   return items.length == list.length || "Values should be different";
  // },
  isDiffer: (list, index, v) => {
    const arr = list.reduce((acc, item, ind) => {
      if (ind === index) return acc;
      acc.push(item.answer_label.trim().toLowerCase());
      return acc;
    }, []);
    if (!arr.includes(v)) return true;
    return "Values should be different";
  },
  quantity: (current, available) =>
    (available && current <= available) || `Available quantity is ${available}`,
  isExpired: (v, repeat) => {
    const minsPerDay = 24 * 60;
    let result = "";
    switch (repeat) {
      case "D":
        result =
          Number(v) <= minsPerDay || `Should not be greater than ${minsPerDay}`;
        break;
      case "W":
        result =
          Number(v) <= 7 * minsPerDay ||
          `Should not be greater than ${7 * minsPerDay}`;
        break;
      case "M":
        result =
          Number(v) <= 30 * minsPerDay ||
          `Should not be greater than ${30 * minsPerDay}`;
        break;
      default:
        result =
          Number(v) <= minsPerDay || `Should not be greater than ${minsPerDay}`;
        break;
    }
    return result;
  },
  alphabet: (v, param) => /^[A-Za-z ]+$/.test(v) || `${param} should be valid`,
  greater: (min, max) =>
    !min ||
    !max ||
    Number(max) > Number(min) ||
    "Max should be greater than min",
  maxMinute: (v) =>
    !v ||
    (Number(v) <= 180 && Number.isInteger(Number(v))) ||
    "Should not be greater than 180",
};
