<template>
  <div class="ma-4 pa-4">
    <h2 class="font-weight-medium">STOCKS</h2>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          hide-details
          label="Search"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="store_id"
          hide-details="auto"
          :items="stores"
          label="Store*"
          item-text="name"
          item-value="id"
          outlined
          dense
          return-object
          :rules="[rules.required]"
          @change="getStocksList()"
        ></v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="3" class="d-flex justify-end">
        <v-btn
          class="accent white--text"
          :disabled="!store_id || !store_id.active"
          @click="dialog = true"
        >
          Add
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-sheet outlined>
          <v-data-table
            :headers="stockHeader"
            :items="stocks"
            fixed-header
            :search="search"
          >
            <template #[`item.action`]="{ item }">
              <v-icon
                :disabled="
                  (item.inventory_item && !item.inventory_item.active) ||
                  !store_id ||
                  !store_id.active
                "
                small
                class="mr-2"
                color="accent"
                @click="edit(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500" persistent>
      <storeItem
        v-if="dialog"
        @closeDialog="resetValues"
        :inventoryItems="inventoryItems"
        :selectedItem="selectedItem"
        :edit="editMode"
        @save="save"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { stockHeader } from "@/helpers/tableHeaders.js";
import { rules } from "@/helpers/formValidation";
import storeItem from "@/components/inventoryItem/storeItem.vue";

export default {
  components: {
    storeItem,
  },
  data() {
    return {
      search: null,
      stockHeader,
      store_id: null,
      rules,
      dialog: false,
      selectedItem: {},
      editMode: false,
    };
  },
  computed: {
    ...mapState({
      stores: (state) => state.site.siteList,
      inventoryItems: (state) => state.inventoryItem.inventoryItems,
      stocks: (state) => state.stock.stocks,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      setInvFilter: "inventoryItem/setFilter",
      // setStoreFilter: "site/setFilter",
    }),
    ...mapMutations("snack", ["showSnackbar"]),
    ...mapActions("site", ["getSites"]),
    ...mapActions("stock", ["getStocks", "addNewStock", "updateStock"]),
    ...mapActions("inventoryItem", ["getInventoryItems"]),

    async init() {
      // this.setStoreFilter(true);
      await this.getSites();
      this.store_id = this.stores[0];
      this.getStocksList();
      this.setInvFilter(true);
      this.getInventoryItems();
    },
    getStocksList() {
      if (this.store_id) {
        this.getStocks(this.store_id?.id);
      }
    },
    edit(item) {
      this.selectedItem = { ...item };
      this.editMode = true;
      this.dialog = true;
    },
    resetValues() {
      this.editMode = false;
      this.dialog = false;
      this.selectedItem = {};
    },
    async save(data) {
      const payload = { ...data };
      payload.type = payload.type || "Add";
      payload.store_id = this.store_id?.id;
      try {
        const cb = this.addNewStock;
        const message = !this.editMode ? "Created" : "Updated";
        await cb(payload);
        this.showSnackbar({
          message: `${message} successfully`,
        });
        this.resetValues();
      } catch (error) {
        this.showSnackbar({
          message: error,
          color: "error",
        });
      }
    },
  },
};
</script>

<style></style>
