<template>
  <v-app>
    <router-view class="secondary"></router-view>
    <v-snackbar
      :timeout="$store.state.snack.snackbar.timeout"
      top
      right
      :color="$store.state.snack.snackbar.color"
      v-model="$store.state.snack.snackbar.show"
    >
      <strong>{{ $store.state.snack.snackbar.message }}</strong>
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          dark
          text
          v-bind="attrs"
          id="clear_notification"
          @click.native="hideSnackbar"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "App",

  data: () => ({}),
  methods: {
    ...mapMutations("snack", ["hideSnackbar"]),
  },
};
</script>
<style>
.v-data-table {
  background-color: var(--v-secondary-base) !important;
}
.v-application--is-ltr .v-data-table--fixed-header .v-data-footer {
  margin-right: 0 !important;
}
.v-date-picker-header {
  background: #fff;
}
.v-date-picker-table {
  background: #fff;
}
</style>
