<template>
  <v-dialog v-model="showDialog" width="400" persistent>
    <v-card style="border-radius: 8px" class="pa-4 secondary">
      <v-card-title class="d-flex justify-center">
        <span class="text-h6 text-center"
          >Are you sure want to mark the <br />
          {{ title }} as {{ active ? "inactive" : "active " }}?</span
        >
      </v-card-title>
      <v-card-actions class="d-flex justify-center px-15">
        <v-btn
          rounded
          depressed
          outlined
          class="accent white--text"
          @click="$emit('delete')"
          >Yes</v-btn
        >
        <v-btn rounded depressed outlined color="accent" @click="$emit('close')"
          >No</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: this.show,
    };
  },
};
</script>

<style></style>
