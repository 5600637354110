import axios from "@/plugins/axios";
import { throwError } from "@/helpers/error";

const state = {
  user: null,
  isAuthenticated: false,
};

const mutations = {
  setUser(state, { data, token }) {
    state.user = data.user;
    state.isAuthenticated = !!token;
  },
  resetState: (state) => {
    state.user = null;
    state.isAuthenticated = false;
  },
};

const actions = {
  async loginUser({ commit }, payload) {
    try {
      const res = await axios.post("sessions/login", payload);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("user", JSON.stringify(res.data.data.user));
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.token}`;
      commit("setUser", res.data);
      return res;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async signUpUser(_, payload) {
    try {
      const res = await axios.post("accounts", payload);
      return res;
    } catch ({ response }) {
      throwError(response);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
