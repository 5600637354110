import axios from "@/plugins/axios";
import { throwError } from "@/helpers/error";

const state = {
  inventoryItems: [],
  schedulers: [],
  schedulerFitler: "",
  filter: "",
};
const mutations = {
  setInventoryItems(state, list) {
    state.inventoryItems = list;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  setSchedulerFilter(state, filter) {
    state.schedulerFitler = filter;
  },
  setSchedulers(state, list) {
    state.schedulers = list;
  },
  resetState: (state) => {
    state.inventoryItems = [];
    state.schedulers = [];
    state.schedulerFitler = "";
    state.filter = "";
  },
};

const actions = {
  async getInventoryItems({ commit }) {
    let url = "/inventory_items";
    if (state.filter) url = `${url}?active=${state.filter}`;
    try {
      const res = await axios.get(url);
      commit("setInventoryItems", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async getInventoryItemById(_, id) {
    try {
      const res = await axios.get(`/inventory_items/${id}`);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async createInventoryItem({ dispatch }, payload) {
    try {
      const res = await axios.post("/inventory_items", {
        inventory_item: payload,
      });
      dispatch("getInventoryItems");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async updateInventoryItem({ dispatch }, payload) {
    try {
      const res = await axios.put(`/inventory_items/${payload.id}`, {
        inventory_item: payload,
      });
      dispatch("getInventoryItems");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async deleteInventoryItem({ dispatch }, { active, id }) {
    try {
      const data = { active: !active };
      const res = await axios.put(`/inventory_items/${id}`, {
        inventory_item: data,
      });
      dispatch("getInventoryItems");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },

  async getInventorySchedulers({ commit }) {
    let url = "/inventory_schedules?active=true";
    try {
      const res = await axios.get(url);
      commit("setSchedulers", res.data.data);
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async createInventoryScheduler({ dispatch }, payload) {
    try {
      const res = await axios.post("/inventory_schedules", {
        inventory_schedule: payload,
      });
      dispatch("getInventorySchedulers");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
  async deleteInventoryScheduler({ dispatch }, { active, id }) {
    try {
      const data = { active: !active };
      const res = await axios.put(`/inventory_schedules/${id}`, {
        inventory_schedule: data,
      });
      dispatch("getInventorySchedulers");
      return res.data.data;
    } catch ({ response }) {
      throwError(response);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
