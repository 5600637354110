<template>
  <div class="ma-4 pa-4">
    <h2 class="font-weight-medium">ADHOC SCHEDULER</h2>
    <searchBar
      @updateSearch="(v) => (search = v)"
      @add="dialog = true"
      :showFilter="false"
    />
    <v-row>
      <v-col cols="12">
        <v-sheet outlined>
          <v-data-table
            :headers="adhocSchedulerHeader"
            :items="schedulerList"
            :search="search"
            fixed-header
          >
            <template #[`item.action`]="{ item }">
              <v-icon small class="mr-2" color="accent" @click="edit(item)">
                mdi-eye
              </v-icon>
              <v-icon
                small
                class="mr-2"
                color="error"
                @click="(deleteDialog = true), (currentItem = item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500" persistent>
      <adhocSchedulerDialog
        v-if="dialog"
        :scheduler="scheduler"
        :storeList="storeList"
        @closeDialog="(dialog = false), (scheduler = null)"
        @save="save"
      />
    </v-dialog>
    <confirmDialogBox
      v-if="deleteDialog"
      :show="deleteDialog"
      title="Delete"
      message1="Are you sure want to delete"
      message2="the schedule"
      @sure="deleteItem"
      @close="deleteDialog = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import searchBar from "@/components/searchBar.vue";
import { adhocSchedulerHeader } from "@/helpers/tableHeaders.js";
import adhocSchedulerDialog from "@/components/scheduler/adhocScheduler.vue";
import { formatDateTime } from "@/helpers/common";
import confirmDialogBox from "@/components/confirmDialog.vue";

export default {
  components: {
    searchBar,
    adhocSchedulerDialog,
    confirmDialogBox,
  },
  data() {
    return {
      search: null,
      dialog: false,
      adhocSchedulerHeader,
      deleteDialog: false,
      currentItem: {},
      scheduler: null,
      schedulerList: [],
      formatDateTime,
    };
  },
  computed: {
    ...mapState({
      schedulers: (state) => state.questionnaire.adhocSchedulers,
      storeList: (state) => state.site.siteList,
      userList: (state) => state.user.usersList,
    }),
  },
  watch: {
    schedulers: {
      handler(v) {
        this.modifyTableList(v);
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations("site", ["setFilter"]),
    ...mapMutations("questionnaire", ["setAdhocSchedulerFilter"]),
    ...mapMutations("snack", ["showSnackbar"]),
    ...mapActions("site", ["getSites"]),
    ...mapActions("questionnaire", [
      "getQuestionnaires",
      "getAdhocSchedulers",
      "createAdhocScheduler",
      "deleteAdhocScheduler",
    ]),
    init() {
      this.setFilter(true);
      this.setAdhocSchedulerFilter("");
      this.getSites();
      this.getAdhocSchedulers();
    },
    async save(data) {
      try {
        await this.createAdhocScheduler(data);
        this.showSnackbar({
          message: `Created successfully`,
        });
        this.resetValues();
      } catch (error) {
        this.showSnackbar({
          message: error,
          color: "error",
        });
      }
    },
    async deleteItem() {
      try {
        await this.deleteAdhocScheduler(this.currentItem);
        this.showSnackbar({
          message: `The Adhoc scheduler has been deleted successfully`,
        });
        this.resetValues();
      } catch (error) {
        this.showSnackbar({
          message: error,
          color: "error",
        });
      }
    },
    edit(item) {
      this.scheduler = { ...item };
      this.dialog = true;
    },
    resetValues() {
      this.dialog = false;
      this.deleteDialog = false;
    },
    filterChanged(v) {
      this.setSchedulerFilter(v);
      this.init();
    },
    modifyTableList(list) {
      this.schedulerList = list?.map((item) => {
        return {
          ...item,
          store_name: item.store.name,
          user_name: item.user?.name,
          audit_name: item.audit?.title,
          estimation: `${item.estimation_time} mins`,
          next_occurrence: this.formatDateTime(item.next_occurrence_datetime),
        };
      });
    },
  },
};
</script>

<style></style>
