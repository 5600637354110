const formatDateTime = (val) => {
  const [year, month, date, time] = val.split(/[-TZ+]/);
  return `${date}-${month}-${year} ${time}`;
};

const formatDate = (val) => {
  const [year, month, date] = val.split("-");
  return `${date}-${month}-${year}`;
};

const getDayOfTheWeek = (val) => {
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const day = new Date(val).getDay();
  return weekdays[day];
};

module.exports = { formatDate, formatDateTime, getDayOfTheWeek };
