<template>
  <div>
    <v-form ref="form">
      <v-row class="mt-1">
        <v-col>
          <h3>Basic Information</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model.trim="detailForm.name"
            hide-details="auto"
            label="Store Name*"
            outlined
            dense
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="detailForm.description"
            hide-details="auto"
            label="Description*"
            outlined
            dense
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3>Address Information</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="detailForm.address.address_line1"
            hide-details="auto"
            label="Address Line 1*"
            outlined
            dense
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="detailForm.address.address_line2"
            hide-details="auto"
            label="Address Line 2"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="detailForm.address.country"
            hide-details="auto"
            label="Country*"
            outlined
            dense
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="detailForm.address.state"
            hide-details="auto"
            label="State*"
            outlined
            dense
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="detailForm.address.city"
            hide-details="auto"
            label="City*"
            outlined
            dense
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="detailForm.address.postcode"
            hide-details="auto"
            label="Postcode*"
            outlined
            dense
            :rules="[rules.required, rules.postalcode]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Geographical Information</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="detailForm.longitude"
            hide-details="auto"
            label="Longitude"
            outlined
            dense
            :rules="[rules.float]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="detailForm.latitude"
            hide-details="auto"
            label="Latitude"
            outlined
            dense
            :rules="[rules.float]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            v-model="detailForm.time_zone"
            hide-details="auto"
            :items="timeZones"
            :disabled="edit"
            label="Timezone*"
            item-text="zone_text"
            item-value="label"
            outlined
            dense
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="detailForm.fencing_radius"
            hide-details="auto"
            label="Check-in Radius"
            outlined
            dense
            :rules="[rules.float]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Additional Information</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            v-for="(item, index) in departments"
            :key="index"
            flat
            color="secondary"
          >
            <v-form
              ref="departmentform"
              v-if="item.active"
              v-model="formValid[index]"
            >
              <v-row>
                <v-col cols="3">
                  <v-autocomplete
                    :disabled="index !== departments.length - 1"
                    v-model="item.id"
                    :items="
                      index !== departments.length - 1
                        ? departmentList
                        : activeDepartments
                    "
                    label="Departments"
                    item-text="name"
                    item-value="id"
                    hide-details="auto"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    :disabled="!item.id || index !== departments.length - 1"
                    v-model="item.auditor"
                    hide-details="auto"
                    :items="
                      index !== departments.length - 1
                        ? auditorList
                        : activeAuditorList
                    "
                    label="Auditor"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    :disabled="!item.id || index !== departments.length - 1"
                    v-model="item.supervisor"
                    hide-details="auto"
                    :items="
                      index !== departments.length - 1
                        ? supervisorList
                        : activeSupervisorList
                    "
                    label="Supervisor"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    icon
                    v-if="index === departments.length - 1"
                    :disabled="!item.auditor || !item.supervisor"
                  >
                    <v-icon color="green" @click="addDepartment(index)"
                      >mdi-plus</v-icon
                    >
                  </v-btn>
                  <v-btn icon v-if="index !== departments.length - 1">
                    <v-icon color="error" @click="removeDepartment(index)"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { rules } from "@/helpers/formValidation";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    timeZones: {
      type: Array,
      default: () => [],
    },
    supervisorList: {
      type: Array,
      default: () => [],
    },
    auditorList: {
      type: Array,
      default: () => [],
    },
    departmentList: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      rules,
      detailForm: {
        auditor: [],
        supervisor: [],
      },
      departments: [{ id: "", supervisor: "", auditor: "", active: true }],
      formValid: [false],
    };
  },
  watch: {
    detailForm: {
      handler(v) {
        this.$emit("valueChanged", v);
      },
      deep: true,
    },
  },
  computed: {
    activeDepartments() {
      return this.departmentList.filter((item) => item.active);
    },
    activeAuditorList() {
      return this.auditorList.filter((item) => item.active);
    },
    activeSupervisorList() {
      return this.supervisorList.filter((item) => item.active);
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations("snack", ["showSnackbar"]),
    init() {
      this.detailForm = Object.assign({}, this.detailForm, this.data);
      this.departments = this.detailForm.supervisor?.length
        ? this.setDepartments()
        : this.departments;
    },
    setDepartments() {
      const departments = [];
      for (const [index, value] of this.detailForm.supervisor.entries()) {
        departments.push({
          id: value.department_id,
          supervisor: value.user_id,
          auditor: this.detailForm.auditor[index].user_id,
          active: value.active,
        });
      }
      this.pushEmpty(departments);
      this.filterDepartmentIds();
      return departments;
    },
    addUser(id, user, role, name) {
      this.detailForm[name].push({
        department_id: id,
        user_id: user,
        role_id: role,
        active: true,
      });
    },
    addDepartment(index) {
      const objectExists = this.departments.some((obj, ind) => {
        if (ind === index) return false;
        return (
          obj.id === this.departments[index].id &&
          obj.auditor === this.departments[index].auditor &&
          obj.supervisor === this.departments[index].supervisor &&
          obj.active === this.departments[index].active
        );
      });

      // If the object doesn't exist, push it to the array
      if (!objectExists) {
        const { id, auditor, supervisor } = this.departments[index];
        this.addUser(id, auditor, 4, "auditor");
        this.addUser(id, supervisor, 3, "supervisor");
        this.filterDepartmentIds();
        this.pushEmpty(this.departments);
      } else {
        this.showSnackbar({
          message: `This combination already exist`,
          color: "error",
        });
      }
    },
    pushEmpty(list) {
      list.push({
        id: "",
        supervisor: "",
        auditor: "",
        active: true,
      });
    },
    removeDepartment(index) {
      this.detailForm.auditor[index].active = false;
      this.detailForm.supervisor[index].active = false;
      this.departments[index].active = false;
      this.filterDepartmentIds();
    },
    filterDepartmentIds() {
      this.detailForm.departmentIds = [
        ...new Set(
          this.detailForm.auditor.reduce((acc, item) => {
            if (item.active) acc.push(item.department_id);
            return acc;
          }, [])
        ),
      ];
      // this.$emit("depIdsChanged", this.detailForm.departmentIds);
    },
  },
};
</script>
