<template>
  <div class="ma-4 pa-4">
    <h2 class="font-weight-medium">STORES</h2>
    <searchBar
      @updateSearch="(v) => (search = v)"
      @add="(dialog = true), (editMode = false)"
      @filterChanged="filterChanged"
    />
    <v-row>
      <v-col cols="12">
        <v-sheet outlined>
          <v-data-table
            :headers="siteHeader"
            :items="sites"
            fixed-header
            :search="search"
            class="secondary"
          >
            <template #[`item.action`]="{ item }">
              <v-icon small class="mr-2" color="accent" @click="edit(item)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-btn
                x-small
                depressed
                color="accent2"
                @click="(deleteDialog = true), (currentItem = item)"
              >
                <v-icon left dark :color="item.active ? 'success' : 'error'"
                  >mdi-circle-medium</v-icon
                >{{ item.active ? "active" : "inactive" }}
              </v-btn>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="900" persistent scrollable>
      <siteDialog
        v-if="dialog"
        @closeDialog="dialog = false"
        :site="site"
        :edit="editMode"
        @save="save"
      />
    </v-dialog>
    <deleteDialogBox
      v-if="deleteDialog"
      :show="deleteDialog"
      @delete="deleteItem"
      @close="deleteDialog = false"
      title="store"
      :active="currentItem.active"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { siteHeader } from "@/helpers/tableHeaders.js";
import searchBar from "@/components/searchBar.vue";
import siteDialog from "@/components/site/form.vue";
import deleteDialogBox from "@/components/deleteDialog.vue";
export default {
  components: {
    searchBar,
    siteDialog,
    deleteDialogBox,
  },
  data() {
    return {
      search: null,
      dialog: false,
      siteHeader,
      editMode: false,
      site: {},
      deleteDialog: false,
      currentItem: {},
    };
  },
  computed: {
    ...mapState({
      sites: (state) => state.site.siteList,
    }),
  },
  created() {
    this.setFilter("");
    this.init();
  },
  methods: {
    ...mapMutations("site", ["setFilter"]),
    ...mapActions("site", [
      "getSites",
      "createSite",
      "updateSite",
      "deleteSite",
    ]),
    ...mapMutations("snack", ["showSnackbar"]),
    init() {
      this.getSites();
    },
    async save(data) {
      try {
        const cb = !this.editMode ? this.createSite : this.updateSite;
        const message = !this.editMode ? "Created" : "Updated";
        await cb(data);
        this.showSnackbar({
          message: `${message} successfully`,
        });
        this.resetValues();
      } catch (error) {
        this.showSnackbar({
          message: error,
          color: "error",
        });
      }
    },
    edit(item) {
      this.site = { ...item };
      this.editMode = true;
      this.dialog = true;
    },
    async deleteItem() {
      try {
        await this.deleteSite(this.currentItem);
        this.showSnackbar({
          message: `The store has been marked as ${
            this.currentItem.active ? "inactive" : "active"
          } successfully`,
        });
        this.resetValues();
      } catch (error) {
        this.showSnackbar({
          message: error,
          color: "error",
        });
      }
    },
    resetValues() {
      this.editMode = false;
      this.dialog = false;
      this.deleteDialog = false;
      this.site = {};
    },
    filterChanged(v) {
      this.setFilter(v);
      this.init();
    },
  },
};
</script>

<style></style>
