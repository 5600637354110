<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      enable-resize-watcher
      fixed
      dark
      left
      app
      clipped
      flat
      color="primary"
      mobile-breakpoint="sm"
    >
      <v-list>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-button font-weight-bold">
              {{ userInfo.name }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="font-weight-regular primary white--text text-button"
              >{{ userInfo.role.name }}</v-list-item-subtitle
            >
            <v-list-item-subtitle class="primary white--text text-caption">
              {{ userInfo.account.company_name }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list dense>
        <div v-for="(item, i) in items" :key="i">
          <v-list-group
            v-if="item.sub_menu"
            :prepend-icon="item.icon"
            v-model="item.active"
            no-action
            active-class="white--text"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="white--text">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="c in item.sub_menu"
              :key="c.title"
              :to="{ name: c.path }"
              active-class="white--text"
            >
              <v-list-item-content>
                <v-list-item-title>{{ c.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else router-link :to="{ name: item.path }" exact>
            <v-list-item-icon>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            class="secondary primary--text font-weight-bold"
            block
            @click="signOut"
          >
            Sign out
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar elevation="1" clipped-left color="primary">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
    </v-app-bar>
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      userInfo: {},
      items: [
        {
          icon: "mdi-chart-box-outline",
          path: "dashboard",
          title: "Dashboard",
        },
        {
          icon: "mdi-account-multiple",
          path: "user",
          title: "Users",
        },
        {
          icon: "mdi-factory",
          path: "department",
          title: "Departments",
        },
        {
          icon: "mdi-alert-circle-outline",
          path: "issueCategory",
          title: "Issue Category",
        },
        {
          icon: "mdi-file-document-check-outline",
          path: "Questionnaires",
          title: "Audits",
        },
        {
          icon: "mdi-store-outline",
          path: "store",
          title: "Stores",
        },
        {
          icon: "mdi-package-variant",
          path: "inventoryItem",
          title: "Inventory Item",
        },
        // {
        //   icon: "mdi-handshake",
        //   path: "transaction",
        //   title: "Transaction",
        // },
        {
          icon: "mdi-trending-up",
          path: "stock",
          title: "Stock",
        },
        {
          icon: "mdi-clock-outline",
          title: " Scheduler",
          sub_menu: [
            {
              icon: "mdi-clock-outline",
              path: "auditScheduler",
              title: "Audit Scheduler",
            },
            {
              icon: "mdi-clock-outline",
              path: "adhocScheduler",
              title: "Audit Adhoc Scheduler",
            },
            {
              icon: "mdi-clock-outline",
              path: "inventoryScheduler",
              title: "Inventory Scheduler",
            },
          ],
        },
        {
          icon: "mdi-file-chart-outline",
          title: "Report",
          sub_menu: [
            {
              icon: "mdi-file-chart-outline",
              path: "auditReport",
              title: "Audit Report",
            },
            {
              icon: "mdi-file-chart-outline",
              path: "issueListReport",
              title: "Issue List Report",
            },
            {
              icon: "mdi-file-chart-outline",
              path: "transactionReport",
              title: "Transaction Report",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    signOut() {
      localStorage.clear();
      this.resetStoreValues();
      this.$router.push("/");
    },
    resetStoreValues() {
      const list = [
        "auth",
        "dashboard",
        "department",
        "inventoryItem",
        "issueCategory",
        "questionnaire",
        "report",
        "role",
        "site",
        "stock",
        "user",
      ];
      list.forEach((item) => {
        this.$store.commit(`${item}/resetState`);
      });
    },
  },
};
</script>

<style scoped>
.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:first-child {
  color: white;
}
</style>
