<template>
  <v-card>
    <v-form ref="form">
      <v-card-title class="font-weight-bold white--text primary">
        {{ edit ? "Edit" : "Add" }} Inventory Item
        <v-spacer></v-spacer>
        <v-btn
          class="d-flex justify-end"
          icon
          plain
          @click="$emit('closeDialog')"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.name"
              label="Name*"
              outlined
              dense
              hide-details="auto"
              :rules="[rules.required]"
            />
          </v-col>
          <!-- <v-col cols="6">
            <v-text-field
              v-model="form.price"
              label="Price*"
              outlined
              dense
              hide-details="auto"
              :rules="[rules.required, rules.float]"
            />
          </v-col> -->
          <v-col cols="12">
            <v-text-field
              v-model="form.description"
              label="Description*"
              outlined
              dense
              hide-details="auto"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded depressed outlined color="accent" @click="save">{{
          edit ? "Update" : "Save"
        }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { rules } from "@/helpers/formValidation";
export default {
  props: {
    inventoryItem: {
      type: Object,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      form: {},
      rules,
      formValid: [false],
      selectedItems: [],
    };
  },
  computed: {
    ...mapState({
      storeList: (state) => state.site.siteList,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("site", ["getSites"]),
    init() {
      this.getSites();
      if (this.edit) {
        this.form = { ...this.inventoryItem };
      }
    },
    save() {
      if (!this.$refs.form.validate()) return;
      const payload = { ...this.form };
      this.$emit("save", payload);
    },
  },
};
</script>

<style></style>
