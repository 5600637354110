<template>
  <v-row class="fill-height">
    <v-col v-if="showChart">
      <LineChart
        class="chart-container"
        :chartData="chartData"
        :chartOptions="chartOptions"
      />
    </v-col>
    <v-col v-else cols="12" class="d-flex align-center justify-center">
      <h3 class="body-1">No Data Available</h3>
    </v-col>
  </v-row>
</template>

<script>
import LineChart from "@/components/dashboard/charts/lineChart";

export default {
  components: {
    LineChart,
  },
  props: {
    chartData: { type: Object, default: () => {}, required: true },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
          },
        },
        elements: {
          line: {
            fill: false,
            tension: 0.1,
          },
        },
        scales: {
          yAxes: [{}],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
      // chartData: {
      //   labels: [
      //     "01-11-2023",
      //     "02-11-2023",
      //     "03-11-2023",
      //     "04-11-2023",
      //     "05-11-2023",
      //     "06-11-2023",
      //   ],
      //   datasets: [
      //     {
      //       label: "Green",
      //       backgroundColor: "green",
      //       borderColor: "green",
      //       data: [50, 20, 80, 20, 55, 70],
      //     },
      //     {
      //       label: "Amber",
      //       backgroundColor: "#FFBF00",
      //       borderColor: "#FFBF00",
      //       data: [5, 7, 20, 10, 60, 40],
      //     },
      //     {
      //       label: "Red",
      //       backgroundColor: "red",
      //       borderColor: "red",
      //       data: [20, 50, 10, 5, 18, 56],
      //     },
      //   ],
      // },
    };
  },
  computed: {
    showChart() {
      const value = this.chartData.datasets.map((i) => i.data.some((v) => v));
      return value.includes(true);
    },
  },
};
</script>

<style scoped>
.chart-container {
  padding: 10px;
  max-height: 300px;
}
</style>
