<template>
  <div class="ma-4 pa-4">
    <h2 class="font-weight-medium">ISSUE CATEGORY</h2>
    <searchBar
      @updateSearch="(v) => (search = v)"
      @add="(dialog = true), (editMode = false)"
      @filterChanged="filterChanged"
    />
    <v-row>
      <v-col cols="12">
        <v-sheet outlined>
          <v-data-table
            :headers="issueCategoryHeader"
            :items="issueCategories"
            :search="search"
            fixed-header
          >
            <template #[`item.action`]="{ item }">
              <v-icon small class="mr-2" color="accent" @click="edit(item)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-btn
                x-small
                depressed
                color="accent2"
                @click="(deleteDialog = true), (currentItem = item)"
              >
                <v-icon left dark :color="item.active ? 'success' : 'error'"
                  >mdi-circle-medium</v-icon
                >{{ item.active ? "active" : "inactive" }}
              </v-btn>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500" persistent>
      <issueCategoryDialog
        v-if="dialog"
        @closeDialog="dialog = false"
        :issueCategory="issueCategory"
        :edit="editMode"
        @save="save"
      />
    </v-dialog>
    <deleteDialogBox
      v-if="deleteDialog"
      :show="deleteDialog"
      @delete="deleteItem"
      @close="deleteDialog = false"
      title="issue category"
      :active="currentItem.active"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import searchBar from "@/components/searchBar.vue";
import { issueCategoryHeader } from "@/helpers/tableHeaders.js";
import issueCategoryDialog from "@/components/issueCategory/form.vue";
import deleteDialogBox from "@/components/deleteDialog.vue";

export default {
  components: {
    searchBar,
    issueCategoryDialog,
    deleteDialogBox,
  },
  data() {
    return {
      search: null,
      dialog: false,
      editMode: false,
      issueCategoryHeader,
      deleteDialog: false,
      currentItem: {},
      issueCategory: {},
    };
  },
  computed: {
    ...mapState({
      issueCategories: (state) => state.issueCategory.issueCategoryList,
    }),
  },
  created() {
    this.setFilter("");
    this.init();
  },
  methods: {
    ...mapMutations("issueCategory", ["setFilter"]),
    ...mapMutations("snack", ["showSnackbar"]),
    ...mapActions("issueCategory", [
      "getIssueCategories",
      "createIssueCategory",
      "updateIssueCategory",
      "deleteIssueCategory",
    ]),
    init() {
      this.getIssueCategories();
    },
    async save(data) {
      try {
        const cb = !this.editMode
          ? this.createIssueCategory
          : this.updateIssueCategory;
        const message = !this.editMode ? "Created" : "Updated";
        await cb(data);
        this.showSnackbar({
          message: `${message} successfully`,
        });
        this.resetValues();
      } catch (error) {
        this.showSnackbar({
          message: error,
          color: "error",
        });
      }
    },
    edit(item) {
      this.issueCategory = { ...item };
      this.editMode = true;
      this.dialog = true;
    },
    async deleteItem() {
      try {
        await this.deleteIssueCategory(this.currentItem);
        this.showSnackbar({
          message: `The issue category has been marked as ${
            this.currentItem.active ? "inactive" : "active"
          } successfully`,
        });
        this.resetValues();
      } catch (error) {
        this.showSnackbar({
          message: error,
          color: "error",
        });
      }
    },
    resetValues() {
      this.editMode = false;
      this.dialog = false;
      this.deleteDialog = false;
      this.issueCategory = {};
    },
    filterChanged(v) {
      this.setFilter(v);
      this.init();
    },
  },
};
</script>

<style></style>
