<template>
  <v-card>
    <v-form ref="form">
      <v-card-title class="font-weight-bold white--text primary">
        {{ edit ? "Edit" : "Add" }} Store Item
        <v-spacer></v-spacer>
        <v-btn
          class="d-flex justify-end"
          icon
          plain
          @click="$emit('closeDialog')"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              v-model="form.inventory_item"
              :items="inventoryItems"
              item-text="name"
              item-value="id"
              label="Item*"
              outlined
              dense
              :readonly="edit"
              return-object
              :rules="[rules.required]"
              @change="itemChanged"
              hide-details="auto"
            />
          </v-col>
          <!-- <v-col cols="6">
            <v-text-field
              v-model="form.price"
              label="Price*"
              outlined
              dense
              readonly
              hide-details="auto"
              :rules="[rules.required, rules.float]"
            />
          </v-col> -->
          <v-col v-if="edit" cols="6" class="my-0 py-0">
            <v-radio-group v-model="form.type" row :rules="[rules.required]">
              <v-radio label="Add" value="Add"> </v-radio>
              <v-radio label="Reduce" value="Reduce"> </v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="form.quantity_to_be_update"
              label="Total Quantity*"
              outlined
              type="number"
              dense
              hide-details="auto"
              :rules="[rules.required, rules.naturalNumber]"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="form.remarks"
              label="Remarks*"
              outlined
              dense
              auto-grow
              rows="2"
              row-height="36"
              hide-details="auto"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded depressed outlined color="accent" @click="save">{{
          edit ? "Update" : "Save"
        }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { rules } from "@/helpers/formValidation";

export default {
  props: {
    inventoryItems: [],
    selectedItem: {},
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      rules,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.edit) {
        this.form = { ...this.selectedItem };
        this.itemChanged(this.form);
      }
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.$emit("save", this.form);
    },
    itemChanged(e) {
      this.form.price = e.price || e.inventory_item?.price;
      this.form.inventory_item_id = e.inventory_item?.id || e.id;
    },
  },
};
</script>

<style></style>
