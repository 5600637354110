import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import department from "./department";
import role from "./role";
import user from "./user";
import site from "./site";
import snack from "./snack";
import questionnaire from "./questionnaire";
import report from "./report";
import dashboard from "./dashboard";
import issueCategory from "./issueCategory";
import inventoryItem from "./inventoryItem";
import stock from "./stock";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    department,
    role,
    user,
    site,
    snack,
    questionnaire,
    report,
    dashboard,
    issueCategory,
    inventoryItem,
    stock,
  },
});

export default store;
