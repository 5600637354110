const state = {
  snackbar: {
    show: false,
    timeout: -1,
    color: "snackbar",
    message: null,
    onStateChange: false,
  },
};

const mutations = {
  showSnackbar(state, settings) {
    state.snackbar = {
      show: true,
      timeout: settings.timeout || 5000,
      color: settings.color || "snackbar",
      message:
        settings.message.charAt(0).toUpperCase() + settings.message.slice(1) ||
        "",
      onStateChange: settings.onStateChange || false,
    };
  },

  hideSnackbar(state) {
    state.snackbar = { message: "", show: false };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
