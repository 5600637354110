<template>
  <v-card>
    <v-form ref="form">
      <v-card-title class="font-weight-bold white--text primary">
        {{ edit ? "Edit User" : "Add User" }}
        <v-spacer></v-spacer>
        <v-btn
          class="d-flex justify-end"
          icon
          plain
          @click="$emit('closeDialog')"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-text-field
          v-model="form.first_name"
          label="First Name*"
          outlined
          dense
          :rules="[rules.required]"
        />
        <v-text-field
          v-model="form.last_name"
          label="Last Name*"
          outlined
          dense
          :rules="[rules.required]"
        />
        <v-text-field
          v-model="form.email"
          label="Email*"
          outlined
          dense
          :rules="[rules.required, rules.email]"
        />
        <v-text-field
          v-model="form.password"
          :label="edit ? 'Password' : 'Password*'"
          outlined
          dense
          type="password"
          :rules="
            edit
              ? form.password
                ? [rules.password]
                : []
              : [rules.required, rules.password]
          "
        />
        <v-autocomplete
          :readonly="!hasPrivilege"
          v-model="form.role_id"
          :items="roles"
          label="Role*"
          outlined
          item-text="name"
          item-value="id"
          dense
          :rules="[rules.required]"
        />
        <v-text-field
          v-model="form.mobile_no"
          label="Mobile*"
          outlined
          dense
          :rules="[rules.required, rules.mobileLength]"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded depressed outlined color="accent" @click="save">{{
          edit ? "Update" : "Save"
        }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { rules } from "@/helpers/formValidation";

export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      form: {},
      rules,
      hasPrivilege: false,
    };
  },
  computed: {
    ...mapState({
      roles: (state) => state.role.rolesList,
    }),
  },
  created() {
    this.init();
    const roleId = JSON.parse(localStorage.getItem("user")).role_id;
    this.hasPrivilege = roleId === 1 || roleId === 2;
  },
  methods: {
    ...mapActions("role", ["getRoles"]),
    init() {
      if (this.edit) this.form = { ...this.user };
      this.getRoles();
    },
    save() {
      if (!this.$refs.form.validate()) return;
      if (!this.form.password) {
        delete this.form.password;
      }
      this.$emit("save", this.form);
    },
  },
};
</script>

<style></style>
