export const siteHeader = [
  {
    text: "Name",
    value: "name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Description",
    value: "description",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Active / Inactive",
    value: "active",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
  },
];

export const userHeader = [
  {
    text: "Name",
    value: "name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Email",
    value: "email",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Phone No.",
    value: "mobile_no",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Role",
    value: "role.name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Active / Inactive",
    value: "active",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
  },
];
export const departmentHeader = [
  {
    text: "Department Name",
    value: "name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Description",
    value: "description",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Email",
    value: "email",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Active / Inactive",
    value: "active",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
  },
];

export const accountHeader = [
  {
    text: "Company Name",
    value: "company_name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Email",
    value: "email",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Phone No.",
    value: "phone_no",
    class: "black--text font-weight-bold secondary",
  },

  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
  },
];
export const questionnaireHeader = [
  {
    text: "Title",
    value: "title",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Number of Questions",
    value: "total_questions",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Status",
    value: "is_published",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Active / Inactive",
    value: "active",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
  },
];

export const reportHeader = [
  {
    text: "Store",
    value: "store_name",
    class: "black--text font-weight-bold secondary",
    width: "10%",
  },
  {
    text: "Audit Title",
    value: "audit.title",
    class: "black--text font-weight-bold secondary",
    width: "15%",
  },
  {
    text: "Auditor",
    value: "user.name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Colors",
    value: "color",
    align: "center",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Scheduled Date & Time",
    value: "scheduled_date_time",
    class: "black--text font-weight-bold secondary",
    width: "10%",
  },
  {
    text: "Completed Date & Time",
    value: "completed_date_time",
    class: "black--text font-weight-bold secondary",
    width: "10%",
  },
  {
    text: "Expiry Date & Time",
    value: "expiry_date_time",
    class: "black--text font-weight-bold secondary",
    width: "10%",
  },
  {
    text: "Type",
    value: "type",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Status",
    value: "status",
    class: "black--text font-weight-bold secondary",
  },

  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
    width: "8%",
  },
];

export const issueCategoryHeader = [
  {
    text: "Name",
    value: "name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Active / Inactive",
    value: "active",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
  },
];

export const inventoryItemsHeader = [
  {
    text: "Name",
    value: "name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Description",
    value: "description",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Active / Inactive",
    value: "active",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
  },
];

export const transactionItemHeader = [
  {
    text: "Item",
    value: "inventory_item.name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Price",
    value: "price",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Quantity",
    value: "quantity",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
  },
];

export const stockHeader = [
  {
    text: "Item",
    value: "inventory_item.name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Stocked Quantity",
    value: "quantity",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
  },
];
export const inventorySchedulerHeader = [
  {
    text: "Store",
    value: "store_name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "User",
    value: "user_name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Frequency",
    value: "frequency_name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Next occurrence",
    value: "next_occurrence",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Expire in",
    value: "estimation",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
  },
];
export const transactionsHeader = [
  {
    text: "Item",
    value: "inventory_item.name",
    class: "black--text font-weight-bold secondary",
  },

  {
    text: "Store",
    value: "store.name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Type",
    value: "type",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Updated Quantity",
    value: "quantity_to_be_update",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Current Quantity",
    value: "current_quantity",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Date",
    value: "date_time",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "User",
    value: "user.name",
    class: "black--text font-weight-bold secondary",
  },
];
export const issueReportHeader = [
  {
    text: "Id",
    value: "id",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Store",
    value: "store.name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Due Date & time",
    value: "due_date_time",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Status",
    value: "status",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Created User",
    value: "created_user.name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Assigned User",
    value: "assigned_user.name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
  },
];
export const auditSchedulerHeader = [
  {
    text: "Store",
    value: "store_name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Audit",
    value: "audit_name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "User",
    value: "user_name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Frequency",
    value: "frequency_name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Next occurrence",
    value: "next_occurrence",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Expire in",
    value: "estimation",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
  },
];
export const adhocSchedulerHeader = [
  {
    text: "Store",
    value: "store_name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Audit",
    value: "audit_name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "User",
    value: "user_name",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Next occurrence",
    value: "next_occurrence",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Expire in",
    value: "estimation",
    class: "black--text font-weight-bold secondary",
  },
  {
    text: "Action",
    value: "action",
    class: "black--text font-weight-bold secondary",
  },
];
