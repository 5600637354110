<template>
  <div class="ma-4 pa-4">
    <h2 class="font-weight-medium">AUDIT REPORTS</h2>
    <v-form ref="form">
      <v-row>
        <v-col cols="3">
          <v-menu
            v-model="fromDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="formattedStartDate"
                outlined
                dense
                label="From Date*"
                hide-details="auto"
                background-color="bg"
                append-icon="mdi-calendar"
                :rules="[rules.required]"
                v-bind="attrs"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.from_date"
              @input="fromDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="toDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="formattedEndDate"
                label="To Date*"
                outlined
                dense
                :placeholder="
                  !search.from_date ? 'choose a from date first' : ''
                "
                hide-details="auto"
                background-color="bg"
                append-icon="mdi-calendar"
                :disabled="!search.from_date"
                :rules="[rules.required]"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.to_date"
              :min="getEndDate()"
              @input="toDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="search.store_id"
            label="Store"
            outlined
            dense
            :items="stores"
            item-text="name"
            item-value="id"
            background-color="bg"
            hide-details="auto"
            @change="onChangeStore()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="search.audit_id"
            label="Audit"
            outlined
            dense
            :items="audits"
            item-text="title"
            item-value="id"
            background-color="bg"
            hide-details="auto"
            @change="onChangeAudit()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="search.user_id"
            label="Auditor"
            outlined
            dense
            :items="auditorList"
            item-text="name"
            item-value="id"
            background-color="bg"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="search.status"
            label="Status"
            outlined
            dense
            :items="statuses"
            item-text="name"
            item-value="id"
            background-color="bg"
            hide-details="auto"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="search.type"
            label="Type"
            outlined
            dense
            :items="['Adhoc', 'Audit']"
            background-color="bg"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
        <!-- <v-col cols="3">
          <v-autocomplete
            v-model="search.section_id"
            label="Section"
            outlined
            dense
            :items="sections"
            :disabled="!search.audit_id"
            item-text="name"
            item-value="id"
            background-color="bg"
            hide-details="auto"
            @change="onChangeSection()"
          ></v-autocomplete>
        </v-col> -->
        <!-- <v-col cols="3">
          <v-autocomplete
            v-model="search.question_id"
            label="Question"
            outlined
            dense
            :items="questions"
            :disabled="
              !!search.store_id || !search.audit_id || !search.section_id
            "
            item-text="name"
            item-value="id"
            background-color="bg"
            hide-details="auto"
          ></v-autocomplete>
        </v-col> -->
        <!-- <v-col cols="3">
          <v-autocomplete
            v-model="search.answer_color"
            label="Answer color"
            outlined
            dense
            :items="colors"
            item-text="name"
            item-value="id"
            background-color="bg"
            hide-details="auto"
          ></v-autocomplete>
        </v-col> -->
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end pt-3">
          <v-btn
            rounded
            depressed
            outlined
            color="accent"
            class="mr-5"
            :disabled="!search.from_date || !search.to_date"
            @click="onGenerateReport"
          >
            <v-icon class="mr-1">mdi-folder-text-outline</v-icon>
            Generate
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            color="accent"
            @click="onClearFilter"
          >
            <v-icon class="mr-1">mdi-close</v-icon>
            Clear
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-sheet v-if="valid" class="mt-2" outlined>
      <v-data-table :headers="headers" :items="auditReports" fixed-header>
        <template #[`item.completed_date_time`]="{ item }">
          {{
            item.completed_date_time
              ? formatDateTime(item.completed_date_time)
              : ""
          }}
        </template>
        <template #[`item.scheduled_date_time`]="{ item }">
          {{ formatDateTime(item.scheduled_date_time) }}
        </template>
        <template #[`item.expiry_date_time`]="{ item }">
          {{ formatDateTime(item.expiry_date_time) }}
        </template>
        <template #[`item.color`]="{ item }">
          <div v-if="item.color">
            <v-avatar color="green" size="20" class="ma-1">
              <span class="white--text">{{ item.color.green }}</span>
            </v-avatar>
            <v-avatar color="amber" size="20" class="ma-1">
              <span class="white--text">{{ item.color.amber }}</span>
            </v-avatar>
            <v-avatar color="red" size="20" class="ma-1">
              <span class="white--text">{{ item.color.red }}</span>
            </v-avatar>
          </div>
        </template>
        <template #[`item.action`]="{ item }">
          <v-icon
            class="mr-2"
            color="accent"
            :disabled="item.status != 'Completed'"
            @click="view(item)"
          >
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </v-sheet>
    <v-dialog v-model="dialog" fullscreen>
      <v-card>
        <v-app-bar flat dark color="primary ">
          <v-toolbar-title class="text-uppercase">{{
            audit.title
          }}</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>
        <v-card-text>
          <v-card outlined class="pa-5 my-5">
            <v-sheet flat color="bg" class="pa-2">
              <v-row dense>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Store name</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        report.store_name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Auditor name</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        report.user && report.user.name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Scheduled Date Time</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        report.scheduled_date_time &&
                        formatDateTime(report.scheduled_date_time)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Completed Date Time</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        report.completed_date_time &&
                        formatDateTime(report.completed_date_time)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Total no.of questions</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        report.audit && report.audit.total_questions
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="3">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-overline accent--text"
                        >Answers</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        <v-avatar color="green" size="20" class="ma-1">
                          <span class="white--text">{{
                            report.color && report.color.green
                          }}</span>
                        </v-avatar>
                        <v-avatar color="amber" size="20" class="ma-1">
                          <span class="white--text">{{
                            report.color && report.color.amber
                          }}</span>
                        </v-avatar>
                        <v-avatar color="red" size="20" class="ma-1">
                          <span class="white--text">{{
                            report.color && report.color.red
                          }}</span>
                        </v-avatar>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-sheet>
          </v-card>
          <v-expansion-panels v-model="sectionActive">
            <v-expansion-panel
              v-for="(detail, dIdx) in audit.sections"
              :key="dIdx"
              class="my-4"
            >
              <v-expansion-panel-header
                expand-icon="mdi-menu-down"
                class="subtitle-1"
              >
                {{ `${dIdx + 1 + ". " + detail.name}` }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="subtitle-2">Questions</th>
                        <th class="text-center subtitle-2">Answer</th>
                        <th class="text-center subtitle-2">Answer Color</th>

                        <th class="text-center subtitle-2">Answer Type</th>
                        <th class="text-center subtitle-2">Attachments</th>
                        <th class="text-center subtitle-2">Issue Id</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, ind) in detail.questions" :key="ind">
                        <td class="caption">{{ item.name }}</td>
                        <td class="caption text-center">
                          {{
                            item.question_answer ? item.question_answer : "-"
                          }}
                        </td>
                        <td class="caption text-center">
                          <v-avatar
                            :color="item.question_answer_color"
                            size="15"
                          >
                          </v-avatar>
                          {{
                            item.question_answer_color
                              ? captilize(item.question_answer_color)
                              : "-"
                          }}
                        </td>

                        <td class="caption text-center">
                          {{ findTypeById(item.type) }}
                        </td>
                        <td class="text-center">
                          <v-icon
                            :disabled="!item.is_attachments_available"
                            class="mr-2"
                            color="accent"
                            @click.prevent="showImages(item.question_answer_id)"
                          >
                            mdi-image-multiple
                          </v-icon>
                        </td>
                        <td class="caption text-center">
                          {{ item.issue_list_id ? item.issue_list_id : "-" }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showImage"
      persistent
      scrollable
      :max-width="getDynamicWidth()"
    >
      <v-card class="secondary">
        <v-card-title class="font-weight-medium secondary--text primary">
          Attachments
          <v-spacer></v-spacer>
          <v-btn icon plain @click="(showImage = false), (images = [])">
            <v-icon color="secondary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-2" custom-scroll>
          <v-container>
            <v-row v-if="images.length > 0">
              <v-col
                v-for="image in images"
                :key="image.id"
                class="d-flex flex-column pa-2"
                cols="4"
              >
                <v-img
                  v-if="image.type === 'image'"
                  :src="`data:image/png;base64,${image.file_binary}`"
                  aspect-ratio="1"
                  @click="selectedImage = image.file_binary"
                  width="200"
                  height="200"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-btn
                  v-if="image.type === 'application'"
                  @click="downloadPdf(image)"
                  width="200"
                  height="200"
                >
                  <v-icon size="200" color="red">mdi-file-pdf-box </v-icon>
                </v-btn>
                <video
                  v-if="image.type === 'video'"
                  controls
                  width="200"
                  height="200"
                  :src="image.videoUrl"
                />
                <p class="text-truncate mb-0">
                  {{ image.name }}
                </p>
              </v-col>
            </v-row>
            <v-row v-else class="flex justify-center pa-8">
              No Attachments
            </v-row>
          </v-container>
          <v-overlay v-if="selectedImage">
            <div class="overlay_btn">
              <v-btn fab color="white" @click="selectedImage = null"
                ><v-icon class="text-h4" color="blue">mdi-close</v-icon></v-btn
              >
            </div>
            <div>
              <v-img
                :src="`data:image/png;base64,${selectedImage}`"
                contain
                @click="selectedImage = null"
                class="image_cover"
              ></v-img>
            </div>
          </v-overlay>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { reportHeader } from "@/helpers/tableHeaders.js";
import { rules } from "@/helpers/formValidation";
import { formatDateTime, formatDate } from "@/helpers/common";

export default {
  components: {},
  data() {
    return {
      search: {
        store_id: null,
        audit_id: null,
        section_id: null,
        question_id: null,
        from_date: null,
        to_date: null,
        status: "ALL",
        type: null,
      },
      statuses: [
        { id: "ALL", name: "ALL" },
        { id: "CP", name: "Completed" },
        { id: "MD", name: "Missed" },
        { id: "CR", name: "Pending" },
      ],
      headers: reportHeader,
      fromDate: false,
      toDate: false,
      formattedStartDate: null,
      formattedEndDate: null,
      stores: [],
      audits: [],
      sections: [],
      questions: [],
      valid: false,
      rules,
      colors: [
        {
          id: "green",
          name: "Green",
        },
        {
          id: "red",
          name: "Red",
        },
        {
          id: "amber",
          name: "Amber",
        },
      ],
      dialog: false,
      audit: {},
      sectionActive: 0,
      answerType: [
        { id: 1, text: "Yes/No" },
        { id: 2, text: "Range" },
        { id: 3, text: "Multi Choice" },
        { id: 4, text: "Number" },
        { id: 5, text: "Text" },
      ],
      report: {},
      formatDateTime,
      formatDate,
      showImage: false,
      images: [],
      selectedImage: null,
    };
  },
  computed: {
    ...mapState({
      auditReports: (state) => state.report.auditReports,
      auditorList: (state) => state.user.auditorList,
    }),
  },
  watch: {
    "search.from_date"(v) {
      this.formattedStartDate = v ? this.formatDate(v) : null;
    },
    "search.to_date"(v) {
      this.formattedEndDate = v ? this.formatDate(v) : null;
    },
    search: {
      handler() {
        this.valid = false;
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("report", [
      "getAuditReports",
      "getAudits",
      "getSections",
      "getQuestions",
      "getStores",
      "getAuditsByStore",
      "getImagesByQuestionId",
    ]),
    ...mapActions("user", ["getAuditorUser"]),

    init() {
      this.getStores("").then((res) => (this.stores = res));
      this.getAudits("?is_published=true").then((res) => (this.audits = res));
      this.getAuditorUser({ active: false });
    },
    getEndDate() {
      if (this.search.to_date && this.search.from_date > this.search.to_date) {
        this.search.to_date = null;
        this.toDate = "";
        return;
      }
      return this.search.from_date;
    },
    onGenerateReport() {
      if (this.$refs.form.validate()) {
        const search = this.objectToQueryString(this.search);
        this.getAuditReports(search).then(() => {
          this.valid = true;
        });
      } else {
        this.$notifier.showMessage({
          msg: "Please correct the error(s) in the form to proceed further",
          color: "error",
        });
      }
    },
    objectToQueryString(obj) {
      const keyValuePairs = [];
      for (const key in obj) {
        if (obj[key] !== null) {
          keyValuePairs.push(
            encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
          );
        }
      }
      return "?" + keyValuePairs.join("&");
    },
    onClearFilter() {
      this.search = {
        store_id: null,
        audit_id: null,
        section_id: null,
        question_id: null,
        from_date: null,
        to_date: null,
        status: "ALL",
        type: null,
      };
      this.valid = false;
      this.fromDate = "";
      this.toDate = "";
      this.$refs.form.resetValidation();
    },
    onChangeStore() {
      this.search.audit_id = null;
      this.search.section_id = null;
      this.search.question_id = null;
      if (this.search.store_id) {
        this.getAuditsByStore(`?store_id=${this.search.store_id}`).then(
          (res) => {
            this.audits = res.map((item) => item.audit);
          }
        );
      } else {
        this.getAudits("?is_published=true").then((res) => (this.audits = res));
      }
    },
    onChangeAudit() {
      this.search.section_id = null;
      this.search.question_id = null;
      if (this.search.audit_id)
        this.getSections(`?audit_id=${this.search.audit_id}`).then(
          (res) => (this.sections = res)
        );
    },
    onChangeSection() {
      this.search.question_id = null;
      if (this.search.section_id)
        this.getQuestions(`?section_id=${this.search.section_id}`).then(
          (res) => (this.questions = res)
        );
    },
    view(item) {
      this.dialog = true;
      this.audit = item.audit;
      this.report = item;
    },
    findTypeById(id) {
      return this.answerType.find((i) => i.id === id)?.text;
    },
    captilize(v) {
      return v[0].toUpperCase() + v.slice(1);
    },
    async showImages(id) {
      this.images = await this.getImagesByQuestionId(id);
      this.images = this.images.map((i) => {
        if (i.type === "video") {
          const blob = this.base64ToBlob(i.file_binary, "video/mp4");
          const dataUrl = URL.createObjectURL(blob);
          return { ...i, videoUrl: dataUrl };
        }
        return i;
      });
      this.showImage = true;
    },

    base64ToBlob(base64String, mimeType) {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      return new Blob([byteArray], { type: mimeType });
    },
    downloadPdf(i) {
      const blob = this.base64ToBlob(i.file_binary, "application/pdf");
      const dataUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = `${i.name}.pdf`; // Set the desired filename

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the click event to start the download
      link.click();
    },
    getDynamicWidth() {
      if (this.images && this.images.length > 0) {
        return "65%";
      }
      return "500px";
    },
  },
};
</script>

<style scoped>
.overlay_btn {
  position: fixed;
  top: 10px;
  right: 30px;
}
.image_cover {
  width: 1200px;
  height: 1200px;
  object-fit: cover;
}
</style>
